import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { IMaskInput } from 'react-imask';

import { Label, Placeholder, TextInputStyles } from './TextInput.common.styles';
import { TextInputProps } from './TextInput.types';

function TextInput({
  label,
  badge,
  errorMessage,
  mask,
  className,
  requiredField,
  onAccept,
  value,
  placeholder,
  ...props
}: TextInputProps) {
  return (
    <Component className={className}>
      {!mask ? (
        <Input value={value} invalid={!!errorMessage} {...props} />
      ) : (
        <InputMasked
          value={value}
          mask={mask}
          onAccept={onAccept}
          invalid={!!errorMessage}
          {...props}
        />
      )}

      {label && (
        <Label hasValue={!!value}>
          {label} {requiredField && <Required>*</Required>}
        </Label>
      )}
      {placeholder && placeholder !== label && !value && (
        <Placeholder>{placeholder}</Placeholder>
      )}
      {badge && <Badge>{badge}</Badge>}
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default TextInput;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Required = styled.span`
  color: rgba(244, 67, 54, 1);
`;

const Input = styled.input<{ invalid?: boolean }>`
  ${TextInputStyles}
`;

const InputMasked = styled(IMaskInput)<{ invalid?: boolean }>`
  ${TextInputStyles}
`;

const Badge = styled.div`
  position: absolute;
  display: flex;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  z-index: 1;
  color: ${(props) => props.theme.black100_alpha50};
  pointer-events: none;

  svg {
    height: 20px;

    path {
      fill: ${(props) => props.theme.black100_alpha50};
    }
  }
`;

const Error = styled.div`
  position: absolute;
  color: rgba(244, 67, 54, 1);
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  bottom: -18px;
`;
