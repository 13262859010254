import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  loadCarDetailedBannerData,
  loadHomeBigBannersData,
  loadHomeSmallBannersData,
  loadMainMenuBannersData,
  loadCarsStockBannerData,
} from '@/services/requests';
import {
  MainMenuBannersData,
  CarsStockBannerResponseType,
  DetailedCarBannerResponseType,
  HomeBannersData,
  CarsStockBannerParams,
  DetailedCarBannerParams,
} from '@/typings/model';

interface State {
  mainMenu: {
    mainMenuStatus: FetchStatus;
    mainMenuData: Nullable<MainMenuBannersData>;
  };

  homeBig: {
    homeBigStatus: FetchStatus;
    homeBigData: Nullable<HomeBannersData>;
  };

  homeSmall: {
    homeSmallStatus: FetchStatus;
    homeSmallData: Nullable<HomeBannersData>;
  };

  carDetailed: {
    carDetailedStatus: FetchStatus;
    carDetailedData: DetailedCarBannerResponseType;
  };

  carsStock: {
    carsStockStatus: FetchStatus;
    carsStockData: CarsStockBannerResponseType;
  };
}

const initialState: State = {
  mainMenu: {
    mainMenuStatus: 'IDLE',
    mainMenuData: null,
  },

  homeBig: {
    homeBigStatus: 'IDLE',
    homeBigData: null,
  },

  homeSmall: {
    homeSmallStatus: 'IDLE',
    homeSmallData: null,
  },

  carDetailed: {
    carDetailedStatus: 'IDLE',
    carDetailedData: {
      banner: null,
    },
  },

  carsStock: {
    carsStockStatus: 'IDLE',
    carsStockData: {
      desktop: null,
      mobile: null,
    },
  },
};

const slice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    // mainMenu
    fetchMainMenuDataLoading: (state) => {
      state.mainMenu.mainMenuStatus = 'LOADING';
    },
    fetchMainMenuDataSuccess: (
      state,
      action: PayloadAction<MainMenuBannersData>
    ) => {
      state.mainMenu.mainMenuStatus = 'SUCCESS';
      state.mainMenu.mainMenuData = action.payload;
    },
    fetchMainMenuDataFailure: (state) => {
      state.mainMenu.mainMenuStatus = 'FAILURE';
    },

    // homeBig
    fetchHomeBigDataLoading: (state) => {
      state.homeBig.homeBigStatus = 'LOADING';
    },
    fetchHomeBigDataSuccess: (
      state,
      action: PayloadAction<HomeBannersData>
    ) => {
      state.homeBig.homeBigStatus = 'SUCCESS';
      state.homeBig.homeBigData = action.payload;
    },
    fetchHomeBigDataFailure: (state) => {
      state.homeBig.homeBigStatus = 'FAILURE';
    },

    // homeSmall
    fetchHomeSmallDataLoading: (state) => {
      state.homeSmall.homeSmallStatus = 'LOADING';
    },
    fetchHomeSmallDataSuccess: (
      state,
      action: PayloadAction<HomeBannersData>
    ) => {
      state.homeSmall.homeSmallStatus = 'SUCCESS';
      state.homeSmall.homeSmallData = action.payload;
    },
    fetchHomeSmallDataFailure: (state) => {
      state.homeSmall.homeSmallStatus = 'FAILURE';
    },

    // carDetailed
    fetchCarDetailedDataLoading: (state) => {
      state.carDetailed.carDetailedStatus = 'LOADING';
    },
    fetchCarDetailedDataSuccess: (
      state,
      action: PayloadAction<DetailedCarBannerResponseType>
    ) => {
      state.carDetailed.carDetailedStatus = 'SUCCESS';
      state.carDetailed.carDetailedData = action.payload;
    },
    fetchCarDetailedDataFailure: (state) => {
      state.carDetailed.carDetailedStatus = 'FAILURE';
    },

    // carsStock
    fetchCarsStockDataLoading: (state) => {
      state.carsStock.carsStockStatus = 'LOADING';
    },
    fetchCarsStockDataSuccess: (
      state,
      action: PayloadAction<CarsStockBannerResponseType>
    ) => {
      state.carsStock.carsStockStatus = 'SUCCESS';
      state.carsStock.carsStockData = action.payload;
    },
    fetchCarsStockDataFailure: (state) => {
      state.carsStock.carsStockStatus = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  fetchMainMenuDataLoading,
  fetchMainMenuDataSuccess,
  fetchMainMenuDataFailure,

  fetchHomeBigDataLoading,
  fetchHomeBigDataSuccess,
  fetchHomeBigDataFailure,

  fetchHomeSmallDataLoading,
  fetchHomeSmallDataSuccess,
  fetchHomeSmallDataFailure,

  fetchCarDetailedDataLoading,
  fetchCarDetailedDataSuccess,
  fetchCarDetailedDataFailure,

  fetchCarsStockDataLoading,
  fetchCarsStockDataSuccess,
  fetchCarsStockDataFailure,
} = slice.actions;

/** Thunks **/

export const fetchMainMenuBannersThunk =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(fetchMainMenuDataLoading());
    try {
      const mainMenuBanners = await loadMainMenuBannersData();
      dispatch(fetchMainMenuDataSuccess(mainMenuBanners));
    } catch (error) {
      dispatch(fetchMainMenuDataFailure());
      return Promise.reject(error);
    }
  };

export const fetchHomeBigBannersThunk =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(fetchHomeBigDataLoading());
    try {
      const homeBigBanners = await loadHomeBigBannersData();
      dispatch(fetchHomeBigDataSuccess(homeBigBanners));
    } catch (error) {
      dispatch(fetchHomeBigDataFailure());
      return Promise.reject(error);
    }
  };

export const fetchHomeSmallBannersThunk =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(fetchHomeSmallDataLoading());
    try {
      const homeSmallBanners = await loadHomeSmallBannersData();
      dispatch(fetchHomeSmallDataSuccess(homeSmallBanners));
    } catch (error) {
      dispatch(fetchHomeSmallDataFailure());
      return Promise.reject(error);
    }
  };

export const fetchCarDetailedBannerThunk =
  (params: DetailedCarBannerParams): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(fetchCarDetailedDataLoading());
    try {
      const carDetailedBanners = await loadCarDetailedBannerData(params);
      dispatch(fetchCarDetailedDataSuccess(carDetailedBanners));
    } catch (error) {
      dispatch(fetchCarDetailedDataFailure());
      return Promise.reject(error);
    }
  };

export const fetchStockCarsBannerThunk =
  (params: CarsStockBannerParams): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(fetchCarsStockDataLoading());
    try {
      const stockCarsBanner = await loadCarsStockBannerData(params);
      dispatch(fetchCarsStockDataSuccess(stockCarsBanner));
    } catch (error) {
      dispatch(fetchCarsStockDataFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectMainMenuBanners = (state: AppState) =>
  state.banners.mainMenu.mainMenuData;

export const selectHomeBigBanners = (state: AppState) =>
  state.banners.homeBig.homeBigData;

export const selectHomeSmallBanners = (state: AppState) =>
  state.banners.homeSmall.homeSmallData;

export const selectCarDetailedBanner = (
  state: AppState
): DetailedCarBannerResponseType => state.banners.carDetailed.carDetailedData;

export const selectStockCarsBanner = (
  state: AppState
): CarsStockBannerResponseType => state.banners.carsStock.carsStockData;
