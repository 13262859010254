import React from 'react';
import styled, { css } from 'styled-components';

import { cookie } from '@tager/web-core';

import { useThemeContext } from '@/theme/hooks';
import SunIcon from '@/assets/svg/theme/sun.svg';
import MoonIcon from '@/assets/svg/theme/moon.svg';
import { getNextTheme } from '@/theme/helpers';
import { media } from '@/utils/mixin';

export function Theme() {
  const { theme, setTheme } = useThemeContext();

  const isLightTheme =
    theme ===
    ('mainLight' || 'b2bLight' || 'hisunLight' || 'jacLight' || 'jetourLight');

  const handleChangeTheme = () => {
    setTheme(getNextTheme(theme));
    cookie.set('theme', getNextTheme(theme));
  };

  return (
    <Component className="themeButton" onClick={handleChangeTheme}>
      {isLightTheme ? <SunIcon /> : <MoonIcon />}
    </Component>
  );
}

const Component = styled.button`
  z-index: 10001;
  position: fixed;
  left: 25px;
  bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  ${media.tablet(css`
    left: 0;
  `)}

  svg {
    width: 25px;
    height: 25px;
  }
`;
