import { ApiService } from '@tager/web-core';

import {
  AlfaBankLeasingQuizFormParams,
  CalculatorInitialValuesResponse,
  PaymentsScheduleParams,
  PaymentsScheduleResponse,
  ProductCardsData,
  ProductCardsParams,
  StaffPaymentsScheduleResponse,
  StaffProductCardsParams,
  StaffProductsCardsData,
} from './typings';

const request = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_FINANCE_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_FINANCE_SERVICE_URL,
  },
}).getRequest();

export function loadProductCardsData({
  type,
  car,
  prepayment,
  period,
}: ProductCardsParams): Promise<ProductCardsData> {
  return request.get({
    path: `/calculator`,
    params: { type, car, prepayment, period },
  });
}

export function loadPaymentsScheduleData({
  id,
  car,
  prepayment,
  period,
}: PaymentsScheduleParams): Promise<PaymentsScheduleResponse[]> {
  return request.get({
    path: `/products/${id}/calendar`,
    params: { car, prepayment, period },
  });
}

export function loadCalculatorInitialValues(
  car: number
): Promise<CalculatorInitialValuesResponse> {
  return request.get({
    path: `/calculator/init`,
    params: { car },
  });
}

export function submitAlfaLeasingQuiz(
  productId: number,
  params: AlfaBankLeasingQuizFormParams
): Promise<{ success: boolean }> {
  return request.post({
    path: `/products/${productId}/alfabank-quiz`,
    body: params,
  });
}

export function loadStaffProductCardsData({
  type,
  car,
  prepayment,
  period,
  total,
}: StaffProductCardsParams): Promise<StaffProductsCardsData> {
  return request.get({
    path: `/staff-calculator?type=${type}&car=${car}&prepayment=${prepayment}&period=${period}${
      total ? `&total=${total}` : ''
    }`,
  });
}

export function loadStaffPaymentsScheduleData({
  id,
  car,
  prepayment,
  period,
  total,
}: PaymentsScheduleParams): Promise<StaffPaymentsScheduleResponse[]> {
  return request.get({
    path: `/staff-products/${id}/calendar?car=${car}&prepayment=${prepayment}&period=${period}${
      total ? `&total=${total}` : ''
    }`,
  });
}
