import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { media } from '@/utils/mixin';

export const StyledToastContainer = styled(ToastContainer)`
  z-index: 10001;
  top: 100px;
  right: 40px;

  ${media.tablet(css`
    right: 20px;
  `)}

  ${media.mobile(css`
    top: 60px;
  `)}
`;
