import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { loadDealerByAlias, loadDealerReviews } from '@/services/requests';
import { Dealer, DealerReviews } from '@/typings/model';

interface State {
  singleDealer: {
    status: FetchStatus;
    data: Nullable<Dealer>;
  };
  singleDealerReviews: {
    status: FetchStatus;
    data: Nullable<DealerReviews>;
  };
}

const initialState: State = {
  singleDealer: {
    status: 'IDLE',
    data: null,
  },
  singleDealerReviews: {
    status: 'IDLE',
    data: null,
  },
};

const slice = createSlice({
  name: 'single-dealer',
  initialState,
  reducers: {
    fetchSingleDealerLoading: (state) => {
      state.singleDealer.status = 'LOADING';
    },
    fetchSingleDealerSuccess: (state, action: PayloadAction<Dealer>) => {
      state.singleDealer.status = 'SUCCESS';
      state.singleDealer.data = action.payload;
    },
    fetchSingleDealerFailure: (state) => {
      state.singleDealer.status = 'FAILURE';
    },

    fetchDealerReviewsLoading: (state) => {
      state.singleDealerReviews.status = 'LOADING';
    },
    fetchDealerReviewsSuccess: (
      state,
      action: PayloadAction<DealerReviews>
    ) => {
      state.singleDealerReviews.status = 'SUCCESS';
      state.singleDealerReviews.data = action.payload;
    },
    fetchDealerReviewsFailure: (state) => {
      state.singleDealerReviews.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  fetchSingleDealerLoading,
  fetchSingleDealerSuccess,
  fetchSingleDealerFailure,

  fetchDealerReviewsLoading,
  fetchDealerReviewsSuccess,
  fetchDealerReviewsFailure,
} = slice.actions;

/** Thunks **/

export const fetchSingleDealerThunk =
  (alias: string): AppThunk<Promise<Dealer>> =>
  async (dispatch) => {
    dispatch(fetchSingleDealerLoading());
    try {
      const dealerData = await loadDealerByAlias(alias);
      dispatch(fetchSingleDealerSuccess(dealerData));
      return dealerData;
    } catch (error) {
      dispatch(fetchSingleDealerFailure());
      return Promise.reject(error);
    }
  };

export const fetchDealerReviewsThunk =
  (
    dealerId: number,
    offset: number,
    sortType: string,
    limit: number
  ): AppThunk<Promise<DealerReviews>> =>
  async (dispatch) => {
    dispatch(fetchDealerReviewsLoading());
    try {
      const dealerReviews = await loadDealerReviews(
        dealerId,
        offset,
        sortType,
        limit
      );
      dispatch(fetchDealerReviewsSuccess(dealerReviews));
      return dealerReviews;
    } catch (error) {
      dispatch(fetchDealerReviewsFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectDealer = (state: AppState): Nullable<Dealer> =>
  state.singleDealer.singleDealer.data;

export const selectDealerReviews = (state: AppState): Nullable<DealerReviews> =>
  state.singleDealer.singleDealerReviews.data;
