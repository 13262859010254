import React from 'react';
import styled, { css } from 'styled-components';

import { formatNumber } from '@tager/web-core';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { CarPreviewProps } from './CarPreview.types';

function CarPreview({
  className,
  carType,
  image,
  brand,
  model,
  equipment,
  currency,
  price,
  body,
  isHiddenPrice,
}: CarPreviewProps) {
  return (
    <Component className={className}>
      <Image loading="lazy" src={image} />
      <Content>
        <Info>
          <Title>
            {brand} {model} {equipment ?? ''}
          </Title>
          <Subtitle>
            {body && body + ','} {carType === 'new' ? 'новый' : 'с пробегом'}
          </Subtitle>
        </Info>
        {price && !isHiddenPrice ? (
          <Price>
            {formatNumber(price)} {currency}
          </Price>
        ) : null}
      </Content>
    </Component>
  );
}

export default CarPreview;

export const Component = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.gray_1};

  ${media.laptop(css`
    padding: 8px;
  `)}
`;

export const Image = styled(Picture)`
  width: 58px;
  height: 58px;
  background: ${colors.white};
  border-radius: 4px;

  ${media.laptop(css`
    width: 50px;
    height: 50px;
  `)}

  ${media.mobile(css`
    width: 74px;
    height: 74px;
  `)}

  picture,
  img {
    border-radius: 4px;
  }

  img {
    object-fit: cover;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 16px;

  ${media.laptop(css`
    margin-left: 5px;
  `)}

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: ${(props) => props.theme.black100};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export const Subtitle = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
  color: ${(props) => props.theme.black100_alpha50};

  ${media.laptop(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  `)}
`;

export const Price = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.black100};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.mobile(css`
    margin-top: 8px;
  `)}
`;
