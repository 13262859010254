import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { CheckboxAgreement } from '@/components/Checkbox';
import Loader from '@/components/Loader';
import { handleValidateField, TypesOfValidate } from '@/utils/textInput';
import CloseButton from '@/components/modals/shared/CloseButton';
import SuccessForm from '@/components/Forms/SuccessForm';
import TextInput from '@/components/TextInput';
import TextArea from '@/components/TextArea';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import {
  initialCallbackFormValues,
  validationCallbackFormSchema,
} from './CallbackForm.helpers';
import { CallbackFormFields, CallbackFormProps } from './CallbackForm.types';

function CallbackForm({ closeModal, innerProps }: CallbackFormProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  useLockBodyScroll();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
  } = useFormik<CallbackFormFields>({
    initialValues: initialCallbackFormValues,
    validationSchema: validationCallbackFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const sentValues = !innerProps.withMessage
          ? { name: values.name, phone: values.phone }
          : {
              name: values.name,
              phone: values.phone,
              message: values.message,
            };

        await innerProps.request?.(sentValues).then(() => setFormSend(true));
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<CallbackFormFields>;
        setErrors(errorMap);
        console.error(error);
      }
    },
  });

  return (
    <Component
      isFormSend={isFormSend}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <CloseButton onClick={closeModal} />

      {!isFormSend ? (
        <Content>
          <Title>{innerProps.title}</Title>
          <InputsContainer>
            <InputRow>
              <TextInput
                id="name"
                name="name"
                label="Имя"
                placeholder="Имя"
                value={values.name}
                onChange={(event) =>
                  handleValidateField(event, handleChange, TypesOfValidate.TEXT)
                }
                errorMessage={
                  errors.name && touched.name ? errors.name : undefined
                }
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="phone"
                name="phone"
                label="Телефон"
                placeholder="+375 (__) ___-__-__"
                value={values.phone}
                onChange={handleChange}
                errorMessage={
                  errors.phone && touched.phone ? errors.phone : undefined
                }
              />
            </InputRow>

            {innerProps.withMessage && (
              <InputRow>
                <TextArea
                  id="message"
                  name="message"
                  label="Комментарий"
                  placeholder="Комментарий"
                  value={values.message}
                  onChange={handleChange}
                />
              </InputRow>
            )}

            <Buttons>
              <StyledButton onClick={closeModal} isWhiteBackground>
                Отмена
              </StyledButton>
              <StyledButton
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                {!isSubmitting ? 'Отправить' : <Loader />}
              </StyledButton>
            </Buttons>

            <CheckboxAgreementWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxAgreementWrapper>
          </InputsContainer>
        </Content>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default CallbackForm;

const Component = styled.form<{ isFormSend?: boolean }>`
  display: flex;
  justify-content: center;
  min-width: 674px;
  padding: 25px 40px;
  border-radius: 10px;
  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    min-width: auto;
    padding: 20px;
  `)}

  ${({ isFormSend }) =>
    isFormSend &&
    css`
      ${media.mobile(css`
        align-items: center;
      `)}
    `}
`;

const Content = styled.div`
  max-width: 332px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.laptop(css`
    font-size: 23px;
    line-height: 25px;
  `)}

  ${media.mobile(css`
    font-size: 19px;
    line-height: 21px;
  `)};

  @media (max-width: 360px) {
    max-width: 240px;
    margin: 0 auto;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 30px;
  `)};
`;

const InputRow = styled.div`
  &:not(:first-child) {
    margin-top: 35px;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const StyledButton = styled(Button)<{ isWhiteBackground?: boolean }>`
  position: relative;
  width: 100%;
  height: 48px;
  padding: 14px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border-radius: 14px;
  letter-spacing: normal;
  background: ${colors.main};
  color: ${colors.white};
  transition: 0.3s;

  &:disabled {
    color: ${(props) => props.theme.black100};
    background: ${(props) => props.theme.gray400_1};
    border: 1px solid ${(props) => props.theme.gray400_2};
    opacity: 0.35;

    &:hover {
      background: ${(props) => props.theme.gray400_1};
    }
  }

  &:hover {
    background: ${colors.main100};
  }

  &:not(:first-child) {
    margin-left: 12px;

    ${media.mobile(css`
      margin-left: 0;
      margin-bottom: 15px;
    `)}
  }

  ${({ isWhiteBackground }) =>
    isWhiteBackground &&
    css`
      color: ${(props) => props.theme.black100};
      background: ${(props) => props.theme.white_2};
      border: 1px solid ${(props) => props.theme.black_alpha20};

      &:hover {
        background: ${(props) => props.theme.white_2};
        opacity: 0.7;
      }
    `}
`;

const CheckboxAgreementWrapper = styled.div`
  margin-top: 14px;
`;
