import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  loadBlogPostsByCategory,
  loadBlogSeoByAlias,
  loadBlogTagsByCategory,
} from '@/services/requests';
import { BlogCategory, BlogPageSeo, BlogPosts, BlogTag } from '@/typings/model';

interface State {
  seoStatus: FetchStatus;
  seo: Nullable<BlogPageSeo>;

  tagsStatus: FetchStatus;
  tags: Nullable<BlogTag[]>;

  postsStatus: FetchStatus;
  posts: Nullable<BlogPosts>;
}

const initialState: State = {
  seoStatus: 'IDLE',
  seo: null,

  tagsStatus: 'IDLE',
  tags: [],

  postsStatus: 'IDLE',
  posts: null,
};

const slice = createSlice({
  name: 'postsList',
  initialState,
  reducers: {
    fetchPostsListSeoLoading: (state) => {
      state.seoStatus = 'LOADING';
    },
    fetchPostsListSeoSuccess: (state, action: PayloadAction<BlogPageSeo>) => {
      state.seoStatus = 'SUCCESS';
      state.seo = action.payload;
    },
    fetchPostsListSeoFailure: (state) => {
      state.seoStatus = 'SUCCESS';
    },

    fetchPostsListTagsLoading: (state) => {
      state.tagsStatus = 'LOADING';
    },
    fetchPostsListTagsSuccess: (state, action: PayloadAction<BlogTag[]>) => {
      state.tagsStatus = 'SUCCESS';
      state.tags = action.payload;
    },
    fetchPostsListTagsFailure: (state) => {
      state.tagsStatus = 'FAILURE';
    },

    fetchPostsListPostsLoading: (state) => {
      state.postsStatus = 'LOADING';
    },
    fetchPostsListPostsSuccess: (state, action: PayloadAction<BlogPosts>) => {
      state.postsStatus = 'SUCCESS';
      state.posts = action.payload;
    },
    fetchPostsListPostsFailure: (state) => {
      state.postsStatus = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  fetchPostsListSeoLoading,
  fetchPostsListSeoSuccess,
  fetchPostsListSeoFailure,

  fetchPostsListTagsLoading,
  fetchPostsListTagsSuccess,
  fetchPostsListTagsFailure,

  fetchPostsListPostsLoading,
  fetchPostsListPostsSuccess,
  fetchPostsListPostsFailure,
} = slice.actions;

/** Thunks **/

export const fetchPostsListSeoThunk =
  (alias: BlogCategory): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(fetchPostsListSeoLoading());
    try {
      const seo = await loadBlogSeoByAlias(alias);
      dispatch(fetchPostsListSeoSuccess(seo));
    } catch (error) {
      dispatch(fetchPostsListSeoFailure());
      return Promise.reject(error);
    }
  };

export const fetchPostsListTagsThunk =
  (category: BlogCategory): AppThunk<Promise<Nullable<BlogTag[]>>> =>
  async (dispatch) => {
    dispatch(fetchPostsListTagsLoading());
    try {
      const tags = await loadBlogTagsByCategory(category);
      dispatch(fetchPostsListTagsSuccess(tags));
      return tags;
    } catch (error) {
      dispatch(fetchPostsListTagsFailure());
      return Promise.reject(error);
    }
  };

export const fetchPostsListPostsThunk =
  (
    category: BlogCategory,
    tags?: string[],
    limit?: number
  ): AppThunk<Promise<Nullable<BlogPosts>>> =>
  async (dispatch) => {
    dispatch(fetchPostsListPostsLoading());
    try {
      const posts = await loadBlogPostsByCategory(
        category,
        tags,
        limit ? limit : 9999
      );
      dispatch(fetchPostsListPostsSuccess(posts));
      return posts;
    } catch (error) {
      dispatch(fetchPostsListPostsFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectPostsListSeo = (state: AppState) => state.postsList.seo;

export const selectPostsListTags = (state: AppState) => state.postsList.tags;

export const selectPostsListPosts = (state: AppState) => state.postsList.posts;

export const selectPostsListPostsStatus = (state: AppState) =>
  state.postsList.postsStatus;
