import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { StepsProps } from './Steps.types';

function Steps({ firstStep, secondStep, prevStep }: StepsProps) {
  return (
    <Component>
      <StepItem
        onClick={prevStep}
        isActive={firstStep.isActive}
        isCompleted={firstStep.isCompleted}
      >
        <StepNumber>1</StepNumber>
        <StepTitle>{firstStep.label}</StepTitle>
      </StepItem>
      <Separator />
      <StepItem
        isActive={secondStep.isActive}
        isCompleted={secondStep.isCompleted}
      >
        <StepNumber>2</StepNumber>
        <StepTitle>{secondStep.label}</StepTitle>
      </StepItem>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    justify-content: space-between;
    align-items: flex-start;
  `)}
`;

const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${colors.gray500};
  color: ${colors.gray500};
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
`;

const StepTitle = styled.span`
  margin-left: 8px;
  color: ${(props) => props.theme.black};
  white-space: nowrap;

  ${media.mobile(css`
    margin-top: 12px;
    text-align: center;
  `)}
`;

const StepItem = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isActive
      ? css`
          ${StepNumber} {
            background: ${colors.main};
            border: none;
            color: ${colors.white};
          }
        `
      : null}

  ${(props) =>
    props.isCompleted
      ? css`
          ${StepNumber} {
            background: #ecedee;
            border: none;
            color: ${colors.main};
          }

          ${StepTitle} {
            color: ${(props) => props.theme.black};
            opacity: 0.5;
          }
        `
      : null}


  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Separator = styled.div`
  min-width: 24px;
  padding: 0 16px;
  margin: 0 16px;
  height: 2px;
  background: ${colors.gray500};

  ${media.mobile(css`
    flex: 1 1 100%;
    margin: 10px -15px 0 0;
  `)}
`;

export default Steps;
