export interface DetailedCarPreviewSpecificationProps {
  items: {
    label: string;
    svgIcon: PreviewSvgIconItems;
    value: string;
    code: PreviewCodeItems;
  }[];
}

export enum PreviewCodeItems {
  BODY_TYPE = 'bodyType',
  ENGINE = 'engine',
  TRANSMISSION = 'transmission',
  GEARBOX = 'gearbox',
  BATTERY = 'battery',
  DISTANCE_LIMIT = 'distanceLimit',
}

export enum PreviewSvgIconItems {
  COUPE = 'Купе',
  VAN = 'Фургон',
  HATCHBACK = 'Хетчбэк',
  CABRIOLET = 'Кабриолет',
  CROSSOVER = 'Кроссовер',
  LIFT_BACK = 'Лифтбэк',
  MINIBUS = 'Микроавтобус',
  BUS = 'Автобус',
  MINIVAN = 'Минивэн',
  SEDAN = 'Седан',
  CHASSIS = 'Шасси',
  STATION_WAGON = 'Универсал',
  SUV = 'Внедорожник',
  PICKUP = 'Пикап',
  ELECTRIC = 'Электро',
  MANUAL = 'Механическая',
  ROBOT = 'Робот',
  DEFAULT = 'DEFAULT',
}
