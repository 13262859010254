import React from 'react';
import styled, { css } from 'styled-components';

import CloseIcon from '@/assets/svg/close-24dp-white.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

function CloseButton(props: Props) {
  return (
    <StyledButton type="button" {...props}>
      <CloseIcon />
    </StyledButton>
  );
}

const StyledButton = styled.button`
  z-index: 10000;
  position: fixed;
  top: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border: 1px solid ${colors.main};

  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  color: ${colors.white};
  transition: 0.15s;

  &:hover {
    border-color: ${colors.main};
    background: ${colors.main};
    color: ${colors.white};

    ${media.tablet(css`
      & > svg {
        fill: ${colors.white};
      }
    `)}
  }

  ${media.tablet(css`
    & > svg {
      fill: ${colors.main};
    }
  `)}

  ${media.tablet(css`
    top: 30px;
    width: 30px;
    height: 30px;
  `)}
`;

export default CloseButton;
