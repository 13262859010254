import * as Yup from 'yup';

import { CallbackFormFields } from './CallbackForm.types';

export const initialCallbackFormValues: CallbackFormFields = {
  name: '',
  phone: '',
  message: '',
};

export const validationCallbackFormSchema: Yup.SchemaOf<CallbackFormFields> =
  Yup.object().shape({
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    message: Yup.string(),
  });
