export type ThemeValuesType = 'main' | 'b2b' | 'hisun' | 'jac' | 'jetour';

export const getTheme = (): ThemeValuesType => {
  return (process.env.NEXT_PUBLIC_THEME as ThemeValuesType) ?? 'main';
};

const colorsMain = {
  main: '#0061ed',
  main100: '#0556cc',

  white: '#fff',
  white_alpha20: 'rgba(255, 255, 255, 0.2)',
  white_alpha60: 'rgba(255, 255, 255, 0.6)',

  black: '#000',
  black100: '#141b27',
  black200: '#151b26',

  gray: '#a4a4a4',
  gray100: '#f4f6f7',
  gray200: '#f3f4f5',
  gray300: '#7c7f85',
  gray400: '#d0d5dc',
  gray500: '#a5adba',
  gray600: '#d8d8d8',
  gray700: '#f6f7f8',
  gray800: '#d1d3d4',
  gray900: '#f5f5f5',
  gray1000: '#eaecef',

  blue: '#0061ed',
  blue100: '#0638CC',
  blue200: '#e8f1ff',
  blue300: '#d4e3fa',
  blue400: '#d8e6fc',
  blue500: '#0556cc',
  blue600: '#505f79',
  blue700: '#172b4d',

  yellow: '#ffd101',
  yellow100: '#ffd159',

  green: '#429834',
  green100: '#5f9717',
  green200: '#e6ffdb',
  green300: '#2ac81c',
  green400: '#3db775',

  red: '#d30000',
  red100: '#f84949',
  red200: '#ffdcdc',
  red300: '#ed0000',
  red400: '#b00505',

  orange: '#ffa51d',
  orange100: '#cd5700',

  purple: '#1a0775',

  purpleGradient: 'linear-gradient(180deg, #0b013C 0%, #1a0775 100%)',
  purpleGradient100: 'linear-gradient(180deg, #1b0875 0%, #fff 76.04%)',
} as const;

const colorsB2b = {
  ...colorsMain,

  main: '#0066b3',
  main100: '#004e89',
} as const;

const colorsHisun = {
  ...colorsMain,

  main: '#670b22',
  main100: '#470818',
} as const;

const colorsJac = {
  ...colorsMain,

  main: '#cc000c',
  main100: '#860008',
} as const;

const colorsJetour = {
  ...colorsMain,

  main: '#68a598',
  main100: '#628788',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'main':
      return colorsMain;
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = [
  '-apple-system',
  "'BlickMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Oxygen'",
  "'Ubuntu'",
  "'Cantarell'",
  "'Fira Sans'",
  "'Droid Sans'",
  "'Helvetica Neue'",
  'sans-serif',
].join(',');

export const fonts = {
  Roboto: `'Roboto', ${fallbackFont}`,
  GrtskTera: `'Grtsk Tera', ${fallbackFont}`,
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktopSmall: 1440,
  desktop: 1540,
};
