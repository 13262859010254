export function scrollToDomElement(element: HTMLElement) {
  window.scrollTo({
    top: element.getBoundingClientRect().top + window.scrollY - 80,
    behavior: 'smooth',
  });
}

export function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function scrollTopAuto() {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
}
