import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { getSiteNavigationMicromarking } from '@/utils/seo';

export function useSiteNavigationMicromarking(): string {
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];
  const navs = headerMenu.map(({ label, link }) => ({
    name: label,
    url: link ?? '',
  }));

  return getSiteNavigationMicromarking(navs);
}
