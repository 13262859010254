import { CarsType } from '@/typings/common';

export const getDetailedCarBreadcrumbMicromarking = (
  carType: CarsType,
  car: {
    brand: string;
    model: string;
    brandAlias: string;
    modelAlias: string;
  }
) => {
  if (carType === 'new') {
    return `{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Каталог автомобилей",
                    "item": "${process.env.NEXT_PUBLIC_ORIGIN}/catalog"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Авто в наличии",
                    "item": "${process.env.NEXT_PUBLIC_ORIGIN}/cars/new"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "${car.brand}",
                    "item": "${process.env.NEXT_PUBLIC_ORIGIN}/cars/new/${car.brandAlias}"
                },
                {
                    "@type": "ListItem",
                    "position": 4,
                    "name": "${car.model}"
                }
              ]
            }`;
  } else {
    return `{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Автомобили в наличии",
                    "item": "${process.env.NEXT_PUBLIC_ORIGIN}/cars/amp"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "${car.brand}",
                    "item": "${process.env.NEXT_PUBLIC_ORIGIN}/cars/amp/${car.brandAlias}"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "${car.model}"
                }
              ]
            }`;
  }
};

export const getDetailedCarProductMicromarking = (
  data: {
    h1: string;
    image: string;
    description: string;
    vin: string;
    brand: string;
    rating?: number | string;
    ratingCount?: number | string;
    url: string;
    price: string | number;
    organization: string;
  },
  options?: {
    ratingOff?: boolean;
  }
) => {
  return `{
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "${data.h1}",
            "image": ["${data.image}"],
            "description": "${data.description}",
            "sku": "${data.vin}",
            "brand": {
              "@type": "Thing",
              "name": "${data.brand}"
            },
            ${
              !options?.ratingOff && data.rating && data.ratingCount
                ? `"aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${data.rating}",
              "reviewCount": "${data.ratingCount}"
            },`
                : ''
            }
            "offers": {
              "@type": "Offer",
              "url": "${data.url}",
              "priceCurrency": "BYN",
              "price": "${data.price}",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStoreOnly",
              "seller": {
                "@type": "Organization",
                "name": "${data.organization}"
              }
            }
          }`;
};

export const getStockAndCatalogProductMicromarking = (
  car: {
    h1: string;
    minPrice: number | string;
    maxPrice: number | string;
    rating?: number | string;
    ratingCount?: number | string;
  },
  options?: {
    ratingOff?: boolean;
  }
) => {
  return `{
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "${car.h1}",
            "offers": {
              "@type": "AggregateOffer",
              "lowPrice": "${car.minPrice}",
              "highPrice": "${car.maxPrice}",
              "priceCurrency": "BYN"
            }
            ${
              !options?.ratingOff && car.rating && car.ratingCount
                ? `,"aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${car.rating}",
              "reviewCount": "${car.ratingCount}"
            }`
                : ''
            }
          }`;
};

export const getSiteNavigationItemMicromarking = (
  name: string,
  url: string
) => {
  return `{
              "@context": "https://schema.org",
              "@type": "SiteNavigationElement",
              "name": "${name}",
              "url": "${url}"
           }
          `;
};

export const getSiteNavigationMicromarking = (
  navs: { name: string; url: string }[]
) => {
  return `{
              "@context": "https://schema.org",
              "@graph":
              [
                ${navs.map(({ name, url }) =>
                  getSiteNavigationItemMicromarking(name, url)
                )}
              ]
           }
          `;
};

export const getEventMicromarking = (data: {
  image: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  url: string;
  organization: string;
  address: string;
}) => {
  return `{
            "@context": "https://schema.org/",
            "@type": "Event",
            "image": "${data.image}",
            "name": "${data.name}",
            "description": "${data.description}",
            "startDate": "${data.startDate}",
            "endDate": "${data.endDate}",
            "url": "${data.url}",
            "location": {
              "@type": "Place",
              "name": "${data.organization}",
              "address": "${data.address}"
            }
          }`;
};
