import styled, { css } from 'styled-components';

import { ButtonPrimary } from '@/components/CarCard/components/Button';
import { media } from '@/utils/mixin';

export const Component = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

/** current step **/

export const StepTitle = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.black};
`;

export const Buttons = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${media.mobile(css`
    flex-direction: column;
    margin-top: 25px;
  `)}
`;

export const StyledNextButton = styled(ButtonPrimary)<{ isLoading?: boolean }>`
  position: relative;
  min-width: 194.92px;
  padding: 15px 57px;
  border-radius: 14px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-left: 12px;

  ${(props) =>
    props.disabled
      ? css`
          color: ${(props) => props.theme.black100} !important;
          background: ${(props) => props.theme.gray400_1} !important;
          border: 1px solid ${(props) => props.theme.gray400_2} !important;
        `
      : null}

  ${media.mobile(css`
    min-height: 52px;
    border-radius: 20px;
    margin-left: 0;

    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  `)}
  
  ${(props) =>
    props.isLoading
      ? css`
          pointer-events: none;
          min-height: 50px;
        `
      : null}
`;

export const StyledPrevButton = styled(StyledNextButton)`
  background: none;
  border: 1px solid ${(props) => props.theme.black_alpha20};
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    order: 2;
    margin-top: 10px;
  `)}
`;

export const StylesForInput = css`
  padding: 14px 16px;
  max-height: 48px;
  font-size: 14px;
  line-height: 20px;

  &::placeholder {
    color: transparent;
  }
`;

export const FormRow = styled.div<{ hasFullHeight?: boolean }>`
  position: relative;
  display: flex;
  margin-top: 35px;
  height: ${(props) => (props.hasFullHeight ? 'calc(100% - 35px)' : 'auto')};

  & > div {
    &:not(:first-child) {
      margin-left: 24px;

      ${media.mobile(css`
        margin-top: 35px;
        margin-left: 0;
      `)}
    }
  }

  input {
    ${StylesForInput}

    ${media.mobile(css`
      min-height: 62px;
    `)}
  }

  ${media.mobile(css`
    flex-direction: column;

    textarea {
      min-height: 160px;
    }
  `)}
`;

export const Form = styled.form``;

export const StepsWrapper = styled.div`
  margin: 32px 0 20px;
`;

export const CarPreviewWrapper = styled.div`
  margin-top: 15px;

  & > div {
    padding: 8px;

    & > div:first-child {
      width: inherit;
      height: inherit;
      max-width: 110px;
      min-width: 56px;
      background: none;
    }

    & > div:last-child {
      margin-left: 20px;

      & > div {
        & > span:first-child {
          font-size: 18px;
          line-height: 20px;
        }

        & > span:last-child {
          font-size: 15px;
          line-height: 18px;
        }
      }

      & > span {
        font-size: 18px;
      }
    }
  }
`;
