import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable, Nullish } from '@tager/web-core';

import {
  DetailedCarData,
  CarStockItemResponse,
  SimilarCarsDataParams,
} from '@/services/stock/typings';
import { AppState, AppThunk } from '@/store/store';
import {
  fetchDetailedCarData,
  fetchSimilarCarsData,
} from '@/services/stock/stock-service';
import { CarsType } from '@/typings/common';

interface State {
  status: FetchStatus;
  data: Nullable<DetailedCarData>;
  similarCars: {
    data: CarStockItemResponse[];
    status: FetchStatus;
  };
}

const initialState: State = {
  status: 'IDLE',
  data: null,
  similarCars: {
    data: [],
    status: 'IDLE',
  },
};

const slice = createSlice({
  name: 'detailed-car',
  initialState,
  reducers: {
    /** DETAILED_CAR **/
    setDetailedCarLoading: (state) => {
      state.status = 'LOADING';
    },
    setDetailedCarSuccess: (
      state,
      action: PayloadAction<Nullable<DetailedCarData>>
    ) => {
      state.data = action.payload;
      state.status = 'SUCCESS';
    },
    setDetailedCarFailure: (state) => {
      state.status = 'FAILURE';
    },
    setDetailedCarIdle: (state) => {
      state.status = 'IDLE';
    },

    /** SIMILAR_CARS **/
    setSimilarCarsLoading: (state) => {
      state.similarCars.status = 'LOADING';
    },
    setSimilarCarsSuccess: (
      state,
      action: PayloadAction<CarStockItemResponse[]>
    ) => {
      state.similarCars.data = action.payload;
      state.similarCars.status = 'SUCCESS';
    },
    setSimilarCarsFailure: (state) => {
      state.similarCars.status = 'FAILURE';
    },
  },
});
export default slice.reducer;

export const {
  setDetailedCarLoading,
  setDetailedCarSuccess,
  setDetailedCarFailure,
  setDetailedCarIdle,

  setSimilarCarsLoading,
  setSimilarCarsSuccess,
  setSimilarCarsFailure,
} = slice.actions;

/********************* THUNKS *********************/

export const getDetailedCarThunk =
  (
    carType: CarsType,
    carId: Nullish<string | number>
  ): AppThunk<Promise<DetailedCarData>> =>
  async (dispatch) => {
    dispatch(setDetailedCarLoading());
    try {
      const carData = await fetchDetailedCarData(carType, carId);
      dispatch(setDetailedCarSuccess(carData));
      return carData;
    } catch (error) {
      dispatch(setDetailedCarFailure());
      return Promise.reject(error);
    }
  };

export const getSimilarCarsThunk =
  (
    carType: CarsType,
    carId: Nullish<string | number>,
    params?: SimilarCarsDataParams
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(setSimilarCarsLoading());
    try {
      const similarCarsData = await fetchSimilarCarsData(
        carType,
        carId,
        params
      );
      dispatch(setSimilarCarsSuccess(similarCarsData));
    } catch (error) {
      dispatch(setSimilarCarsFailure());
      return Promise.reject(error);
    }
  };

/******************** SELECTORS ********************/

export function selectDetailedCarData(state: AppState) {
  return state.detailedCarNew.data;
}

export function selectDetailedCarStatus(state: AppState) {
  return state.detailedCarNew.status;
}

export function selectSimilarCarsData(state: AppState) {
  return state.detailedCarNew.similarCars.data;
}
