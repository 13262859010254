import { OptionType } from '@/typings/common';
import { StockFilterMultiSelectPartsSelectedOptionType } from '@/components/StockFilterMultiSelectParts/types';
import { ColorOptionType } from '@/components/StockColorPicker/types';

export enum GearboxNames {
  AUTOMAT = 'Автомат',
  MANUAL = 'Механическая',
}

export interface ConvertedFilters {
  brand: OptionType[];
  model: StockFilterMultiSelectPartsSelectedOptionType[];
  complectation: StockFilterMultiSelectPartsSelectedOptionType[];
  gearboxes: StockFilterMultiSelectPartsSelectedOptionType[];
  bodies: OptionType[];
  wheels: OptionType[];
  colors: ColorOptionType[];
  fuelTypes: OptionType[];
  locations: OptionType[];
  batteries: OptionType[];
  vat: OptionType;
  priceRange: { min: string; max: string };
  mileage: { min: string; max: string };
  years: { min: string; max: string };
  powerReserve: { min: string; max: string };
  batteryCapacity: { min: string; max: string };
  engineVolume: { min: OptionType; max: OptionType };
  specials: {
    superPrice: OptionType;
    guarantee: OptionType;
    approved: OptionType;
    partnerCar: OptionType;
    available: OptionType;
    onStock: OptionType;
  };
  page: number;
  sort: OptionType;
}

export interface SelectedFilterFromUrlTypes {
  brand?: string[];
  model?: string[];
  complectation?: string[];
  gearboxes?: string[];
  bodies?: string[];
  wheels?: string[];
  colors?: string[];
  fuelTypes?: string[];
  locations?: string[];
  batteries?: string[];
  vat?: string;
  minPrice?: string;
  maxPrice?: string;
  minMileage?: string;
  maxMileage?: string;
  minYear?: string;
  maxYear?: string;
  minEngineVolume?: string;
  maxEngineVolume?: string;
  minPowerReserve?: string;
  maxPowerReserve?: string;
  minBatteryCapacity?: string;
  maxBatteryCapacity?: string;
  superPrice?: string;
  approved?: string;
  guarantee?: string;
  partnerCar?: string;
  available?: string;
  onStock?: string;
  page?: string;
  sort?: string;
}
