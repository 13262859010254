import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { OptionType } from '@/typings/common';
import { CatalogCar, CatalogFiltersData } from '@/services/catalog/typings';
import {
  loadCatalogFiltersData,
  loadCatalogItemsData,
} from '@/services/catalog/catalog-service';

interface State {
  catalogView: 'portrait' | 'landscape';
  carsOnStockFilter: boolean;
  isSidebarOpen: boolean;
  activeFilterCheckboxes: { label: string; value: string }[];
  catalogFilters: {
    catalogFiltersStatus: FetchStatus;
    catalogFiltersData: Nullable<CatalogFiltersData>;
  };
  catalogItems: {
    catalogItemsStatus: FetchStatus;
    catalogItemsData: CatalogCar[];
  };
  filteredItems: CatalogCar[];
}

const initialState: State = {
  catalogView: 'portrait',
  carsOnStockFilter: false,
  isSidebarOpen: false,
  activeFilterCheckboxes: [],
  catalogFilters: {
    catalogFiltersStatus: 'IDLE',
    catalogFiltersData: null,
  },
  catalogItems: {
    catalogItemsStatus: 'IDLE',
    catalogItemsData: [],
  },
  filteredItems: [],
};

const slice = createSlice({
  name: 'catalog-new',
  initialState,
  reducers: {
    // -----------------------FILTERS------------------------------//

    fetchCatalogFiltersLoading: (state) => {
      state.catalogFilters.catalogFiltersStatus = 'LOADING';
    },
    fetchCatalogFiltersSuccess: (
      state,
      action: PayloadAction<CatalogFiltersData>
    ) => {
      state.catalogFilters.catalogFiltersStatus = 'SUCCESS';
      state.catalogFilters.catalogFiltersData = action.payload;
    },
    fetchCatalogFiltersFailure: (state) => {
      state.catalogFilters.catalogFiltersStatus = 'FAILURE';
    },

    // ----------------------ITEMS-------------------------------//
    fetchCatalogItemsLoading: (state) => {
      state.catalogItems.catalogItemsStatus = 'LOADING';
    },
    fetchCatalogItemsSuccess: (state, action: PayloadAction<CatalogCar[]>) => {
      state.catalogItems.catalogItemsStatus = 'SUCCESS';
      state.catalogItems.catalogItemsData = action.payload;
    },
    fetchCatalogItemsFailure: (state) => {
      state.catalogItems.catalogItemsStatus = 'FAILURE';
    },
    setFilteredItems: (state, action: PayloadAction<CatalogCar[]>) => {
      state.filteredItems = action.payload;
    },

    //-----------------------------------------------------------//

    setCatalogView: (
      state,
      action: PayloadAction<'portrait' | 'landscape'>
    ) => {
      state.catalogView = action.payload;
    },
    setCarsOnStockFilter: (state, action: PayloadAction<boolean>) => {
      state.carsOnStockFilter = action.payload;
    },
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
    },
    setActiveFilterCheckboxes: (state, action: PayloadAction<OptionType[]>) => {
      state.activeFilterCheckboxes = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  fetchCatalogFiltersLoading,
  fetchCatalogFiltersSuccess,
  fetchCatalogFiltersFailure,

  fetchCatalogItemsLoading,
  fetchCatalogItemsSuccess,
  fetchCatalogItemsFailure,
  setFilteredItems,

  setCatalogView,
  setCarsOnStockFilter,
  setIsSidebarOpen,
  setActiveFilterCheckboxes,
} = slice.actions;

/** Thunks **/
export const fetchCatalogFiltersThunk =
  (): AppThunk<Promise<CatalogFiltersData>> => async (dispatch) => {
    dispatch(fetchCatalogFiltersLoading());
    try {
      const filtersData = await loadCatalogFiltersData();
      dispatch(fetchCatalogFiltersSuccess(filtersData));
      return filtersData;
    } catch (error) {
      dispatch(fetchCatalogFiltersFailure());
      return Promise.reject(error);
    }
  };

export const fetchCatalogItemsThunk =
  (): AppThunk<Promise<CatalogCar[]>> => async (dispatch) => {
    dispatch(fetchCatalogItemsLoading());
    try {
      const catalogItems = await loadCatalogItemsData();
      dispatch(fetchCatalogItemsSuccess(catalogItems));
      return catalogItems;
    } catch (error) {
      dispatch(fetchCatalogItemsFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export function getCatalogFilters(state: AppState) {
  return state.catalogNew.catalogFilters;
}

export function getCatalogItems(state: AppState) {
  return state.catalogNew.catalogItems;
}

export function getFilteredItems(state: AppState) {
  return state.catalogNew.filteredItems;
}

export function getCatalogView(state: AppState): 'portrait' | 'landscape' {
  return state.catalogNew.catalogView;
}

export function getCarsOnStockFilter(state: AppState): boolean {
  return state.catalogNew.carsOnStockFilter;
}

export function getIsSidebarOpen(state: AppState): boolean {
  return state.catalogNew.isSidebarOpen;
}

export function getActiveFilterCheckboxes(state: AppState): OptionType[] {
  return state.catalogNew.activeFilterCheckboxes;
}
