import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, FormikTouched } from 'formik';
import DatePicker from 'react-datepicker';

import { Nullable } from '@tager/web-core';

import { TestDriveLocation } from '@/typings/model';
import { FormRow } from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import DateIcon from '@/assets/svg/date-icon.svg';
import TimeIcon from '@/assets/svg/time.svg';
import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import { media } from '@/utils/mixin';
import { getFreeTimeForDealer } from '@/services/requests';
import Loader from '@/components/Loader';
import TextInput from '@/components/TextInput';
import { DatePickerStyles } from '@/components/styles';
import LocationCard from '@/components/LocationCard';
import {
  CarProps,
  ITestDriveFormUserValuesData,
} from '@/components/modals/DetailedCarModal/components/Application/common.types';
import { getDayAfterTomorrowDate, getTomorrowDate } from '@/utils/date';
import { CarsType } from '@/typings/common';

import TimePicker from '../../../../../../../../Picker/TimePicker';

import InfoMessage from './components/InfoMessage';
import { pad } from './utils';

interface Props {
  touched: FormikTouched<ITestDriveFormUserValuesData>;
  locations?: Array<TestDriveLocation>;
  typeState: CarsType;
  values: ITestDriveFormUserValuesData;
  errors: FormikErrors<ITestDriveFormUserValuesData>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  closeModal?: () => void;
  hasErrors: boolean;
  nextStep: () => void;
  setIsShownTimePicker: (isShown: boolean) => void;
  isShowTimePicker: boolean;
  outdoorAvailable?: boolean;
  testDriveDate: Nullable<Array<string>>;
  setTestDriveDate: Dispatch<SetStateAction<Nullable<string[]>>>;
  car: CarProps;
}

function LocationStep({
  touched,
  locations,
  values,
  onChange,
  closeModal,
  errors,
  hasErrors,
  nextStep,
  setFieldValue,
  setIsShownTimePicker,
  isShowTimePicker,
  outdoorAvailable,
  typeState,
  testDriveDate,
  setTestDriveDate,
  car,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [isDealerPicked, setIsDealerPicked] = useState(false);
  const [isDatePicked, setIsDatePicked] = useState(false);

  function handleClickDealer(dealerId: number | null) {
    if (dealerId === values.dealerId) {
      return;
    }
    setFieldValue('date', null);
    setFieldValue('time', '');
    setFieldValue('dealerId', dealerId);
    setFieldValue('outsideTestDrive', false);
  }

  function handleClickOutsideTestDrive() {
    if (values.outsideTestDrive) {
      return;
    }
    setTestDriveDate(null);
    setFieldValue('date', null);
    setFieldValue('time', '');
    setFieldValue('dealerId', null);
    setFieldValue('outsideTestDrive', true);
  }

  useEffect(() => {
    if (values.outsideTestDrive) {
      setIsShownTimePicker(true);
    }
  }, [values.outsideTestDrive]);

  useEffect(() => {
    const getTimeForDealer = async (): Promise<void> => {
      setTestDriveDate(null);
      setFieldValue('time', '');
      setLoading(true);

      if (values.dealerId && values.date) {
        const date = values.date;
        const formatDate = `${date.getUTCFullYear()}-${pad(
          date.getUTCMonth() + 1
        )}-${pad(date.getUTCDate() + 1)}`;
        const dealerId =
          locations?.find((location) => location.dealerId === values.dealerId)
            ?.id ?? 0;

        const result = await getFreeTimeForDealer(
          car.id,
          typeState,
          formatDate,
          dealerId
        );

        if (result.length !== 0) {
          setTestDriveDate(result);
        }
      }
      setIsShownTimePicker(true);
      setLoading(false);
    };

    if ((values.dealerId && !values.time) || values.time) {
      getTimeForDealer();
    }
  }, [values.date]);

  function handleClickInput(event: React.MouseEvent) {
    event.stopPropagation();
    setIsShownTimePicker(!isShowTimePicker);
  }

  if (!locations || locations.length === 0) {
    return null;
  }

  function showInfoDealerMessage(event: React.MouseEvent) {
    event.preventDefault();
    setIsDatePicked(false);
    if (values.dealerId || values.outsideTestDrive) return;
    setIsDealerPicked(true);
  }

  function showInfoDateMessage(event: React.MouseEvent) {
    event.preventDefault();
    setIsDealerPicked(false);
    if (values.date) {
      return;
    }
    setIsDatePicked(true);
  }

  function closeInfoMessage() {
    if (isDealerPicked) {
      setIsDealerPicked(false);

      return;
    }

    if (isDatePicked) {
      setIsDatePicked(false);

      return;
    }
  }

  return (
    <Component onClick={closeInfoMessage}>
      {loading && <Loader />}
      <LocationList>
        {locations &&
          locations.length > 0 &&
          locations.map(
            (location, index) =>
              location.address && (
                <LocationCard
                  key={index}
                  checked={values.dealerId === location.dealerId}
                  onClick={() => handleClickDealer(location.dealerId)}
                  {...location}
                />
              )
          )}
        {outdoorAvailable && (
          <LocationCard
            name="Выездной тест-драйв"
            checked={values.outsideTestDrive}
            onClick={() => handleClickOutsideTestDrive()}
            phone="+375 (44) 520 08 20"
            address="Услуга доступна только для г. Минска"
            isOutsideTestDrive={true}
          />
        )}
      </LocationList>
      <FormRow>
        <PickerWrapper onClick={(event) => showInfoDealerMessage(event)}>
          {isDealerPicked && <InfoMessage label={'Сначала выберите место'} />}
          <DatePicker
            disabled={!values.outsideTestDrive && Boolean(!values.dealerId)}
            placeholderText="Дата"
            locale="ru"
            minDate={
              typeState === 'new'
                ? getDayAfterTomorrowDate()
                : getTomorrowDate()
            }
            selected={values.date}
            popperClassName="some-custom-class"
            popperPlacement="top-start"
            onChangeRaw={(e) => e.preventDefault()}
            dateFormat={['dd.MM.yyyy']}
            fixedHeight
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, -3],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  tether: false,
                  altAxis: true,
                },
              },
            ]}
            customInput={
              <TextInput
                name="date"
                value={values.date ? values.date.getDate() : ''}
                label="Дата"
                onChange={onChange}
                badge={<DateIcon />}
              />
            }
            onChange={(date) => {
              setFieldValue('date', date);
            }}
          />
        </PickerWrapper>

        <PickerWrapper onClick={(event) => showInfoDateMessage(event)}>
          {isDatePicked && !values.time && (
            <InfoMessage label={'Сначала выберите дату'} />
          )}
          <TextInput
            readOnly
            disabled={!values.date}
            name="time"
            value={values.time}
            label="Время"
            placeholder="Время"
            onChange={onChange}
            onClick={(event) => handleClickInput(event)}
            badge={<TimeIcon />}
            errorMessage={errors.time && touched.time ? 'noop' : undefined}
          />
          {((isShowTimePicker && values.date) ||
            (values.date && values.outsideTestDrive && isShowTimePicker)) && (
            <TimePicker
              value={values.time}
              typeState={typeState}
              times={testDriveDate}
              onClick={(value) => {
                setFieldValue('time', value);
                setIsShownTimePicker(false);
              }}
              onClose={() => setIsShownTimePicker(false)}
              values={values}
            />
          )}
        </PickerWrapper>
      </FormRow>

      <S.Buttons>
        <S.StyledPrevButton type="button" onClick={closeModal}>
          отмена
        </S.StyledPrevButton>
        <S.StyledNextButton
          type="button"
          disabled={hasErrors}
          onClick={nextStep}
        >
          далее
        </S.StyledNextButton>
      </S.Buttons>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 10px;
  ${DatePickerStyles};
`;

const LocationList = styled.div`
  position: relative;
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const PickerWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export default LocationStep;
