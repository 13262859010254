import { Nullable } from '@tager/web-core';

/** For users **/

export interface ProductCardsData {
  valid: ProductCard[];
  recommended: ProductCard[];
}

export interface ProductCard {
  id: number;
  currency: string;
  logo: string;
  name: string;
  description: string;
  firstPayment: number;
  lastPayment: number;
  monthPaymentHint: string;
  overPayment: number;
  prepayment: number;
  period: number;
  periodDiscount: Nullable<number>;
  periodHint: string;
  isAlfaBankPreOrder: boolean;
  priority: number;
}

export interface ProductCardsParams {
  type: string;
  car: number;
  prepayment: number;
  period: number;
}

export interface PaymentsScheduleParams {
  id: number;
  car: number;
  prepayment: number;
  period: number;
  total?: number;
}

export interface PaymentsScheduleResponse {
  month: number;
  year: number;
  payment: string;
}

export interface PaymentsScheduleResponseConverted {
  year: number;
  payments: { month: number; payment: string }[];
}

export enum CreditTypes {
  Credit = 'CREDIT',
  Leasing = 'LEASING',
}

export interface CalculatorInitialValuesResponse {
  [CreditTypes.Credit]: {
    minPeriod: number;
    maxPeriod: number;
    defaultPeriod: number;
    minPrepayment: number;
  };

  [CreditTypes.Leasing]: {
    minPeriod: number;
    maxPeriod: number;
    defaultPeriod: number;
    minPrepayment: number;
  };
}

export enum SortTypes {
  MinPayment = 'min_payment',
  MinOverpayment = 'min_overpayment',
}

export interface AlfaBankLeasingQuizFormParams {
  car: number;
  prepayment: number;
  period: number;
  birthdate: string;
  lastJobAt: string;
  isBelarus: boolean;
  averageIncome: number;
  haveDebts: boolean;
}

/** For staff **/

export interface StaffProductCardsParams {
  type: CreditTypes;
  car: number;
  prepayment: number;
  period: number;
  total?: number;
}

export interface StaffProductsCardsData {
  valid: StaffProductCardData[];
  recommended: StaffProductCardData[];
}

export interface StaffProductCardData {
  id: number;
  priority: number;
  name: string;
  logo: string;
  currency: string;
  period: number;
  periodDiscount: number;
  rate: number;
  rateDiscount: number;
  fundingAmount: number;
  firstPayment: number;
  lastPayment: number;
  prepayment: number;
  isCasco: boolean;
  cascoInfo: string;
  comment: string;
}

export interface StaffCalculatorValues {
  carCost: number;
  prepayment: number;
  period: number;
}

export interface StaffPaymentsScheduleResponse {
  month: number;
  year: number;
  payment: number;
  percent: number;
  mainDebt: number;
  remainder: number;
}
