import React, { useEffect } from 'react';
import App from 'next/app';
import Head from 'next/head';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import dateFnsRuLocale from 'date-fns/locale/ru';
// @ts-ignore
import momentRuLocale from 'moment/locale/ru';
import smoothscroll from 'smoothscroll-polyfill';

import '@/assets/css/index.css';

import {
  cookie,
  isServer,
  useFixedVhProperty,
  useProgressBar,
} from '@tager/web-core';
import { ModalProvider } from '@tager/web-components';
import { AdminBar } from '@tager/web-panel';

import withRedux from '@/hocs/withRedux';
import withPerfLogs from '@/hocs/withPerfLogs';
import { CustomApp_Component } from '@/typings/hocs';
import { checkAuthorizationThunk } from '@/store/reducers/auth';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { fetchMainMenuBannersThunk } from '@/store/reducers/banners';
import { userApiService } from '@/services/users/users-service';
import { getUserPersonalDataThunk } from '@/store/reducers/cabinet';
import { fetchDealersThunk } from '@/store/reducers/dealers';
import { ThemeProvider } from '@/theme';
import { StyledToastContainer } from '@/components/ToastContainer';
import { fonts } from '@/constants/theme';
import { useSiteNavigationMicromarking } from '@/hooks/useSiteNavigationMicromarking';

moment.updateLocale('ru', momentRuLocale);
registerLocale('ru', dateFnsRuLocale);

/**
 * Custom App documentation
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp: CustomApp_Component = (props) => {
  const siteNavigationMicromarking = useSiteNavigationMicromarking();

  useProgressBar({ showSpinner: false });

  useFixedVhProperty({ shouldListenResize: true });

  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // @ts-ignore
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <AdminBar />
      <style jsx global>
        {`
          :root {
            --font-family: ${fonts.Roboto};
          }
        `}
      </style>
      <ThemeProvider>
        <ModalProvider>
          <Component {...modifiedPageProps} />
        </ModalProvider>
      </ThemeProvider>
      <StyledToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <script
        id="siteNavigation"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: siteNavigationMicromarking,
        }}
      />
    </>
  );
};

CustomApp.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  const accessTokenCookieName =
    process.env.NEXT_PUBLIC_COOKIE_ACCESS_TOKEN_PARAM || 'accessToken';

  if (isServer() && ctx.req) {
    userApiService.setAccessToken(cookie.get(accessTokenCookieName, ctx.req));

    const hrStart = process.hrtime();

    await Promise.all([
      ctx.store.dispatch(fetchDealersThunk()),
      ctx.store.dispatch(getMenuItemListThunk('header')),
      ctx.store.dispatch(getMenuItemListThunk('footer')),
      ctx.store.dispatch(getMenuItemListThunk('header_mobile')),
      ctx.store.dispatch(fetchMainMenuBannersThunk()),
      ctx.store.dispatch(getSettingItemListThunk()),
      ctx.store.dispatch(checkAuthorizationThunk()),
      ctx.store.dispatch(getUserPersonalDataThunk()),
    ]);

    const hrEnd = process.hrtime(hrStart);

    console.info('Execution time (hr): %ds %dms', hrEnd[0], hrEnd[1] / 1000000);
  }

  /** calls page's `getInitialProps` and fills `appProps.pageProps` */
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default withRedux(withPerfLogs(CustomApp));
