import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { getTheme, ThemeValuesType } from '@/constants/theme';
import Link from '@/components/Link';

import { ButtonVariant, ReservedButtonVariant } from './types';

const getDefaultVariantCssMap = (): Record<
  ReservedButtonVariant,
  CssSnippet
> => {
  return {
    contained: css`
      border-radius: 20px;
      background: #0061ed;
      color: #fff;

      &:hover {
        background: #0556cc;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    `,
    outlined: css`
      border: 1px solid ${(props) => props.theme.main};
      border-radius: 20px;
      background: ${(props) => props.theme.white_2};
      color: ${(props) => props.theme.main};

      &:hover {
        border: 1px solid #0556cc;
        background: #0556cc;
        color: #fff;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    `,
    outlined100: css``,
  };
};

export const getVariantCssMap = (
  themeType?: ThemeValuesType
): Record<ReservedButtonVariant, CssSnippet> => {
  const currentTheme = themeType ?? getTheme();

  switch (currentTheme) {
    case 'main':
      return getDefaultVariantCssMap();
    case 'b2b':
      return {
        contained: css`
          border-radius: 20px;
          background: #0066b3;
          color: #fff;

          &:hover {
            background: #004e89;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined: css`
          border: 1px solid #0066b3;
          border-radius: 20px;
          background: #fff;
          color: #0066b3;

          &:hover {
            border: 1px solid #004e89;
            background: #004e89;
            color: #fff;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined100: css``,
      };
    case 'hisun':
      return {
        contained: css`
          background: #670b22;
          color: #fff;

          &:hover {
            background: #470818;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined: css`
          border: 1px solid #333;
          background: transparent;
          color: #333;

          &:hover {
            opacity: 0.7;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined100: css`
          border: 1px solid white;
          background: transparent;
          color: #fff;

          &:hover {
            opacity: 0.7;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
      };
    case 'jac':
      return {
        contained: css`
          border-radius: 20px;
          background: #cc000c;
          color: #fff;

          &:hover {
            background: #860008;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined: css`
          border: 1px solid #1d1d1b;
          border-radius: 20px;
          background: #fff;
          color: #1d1d1b;

          &:hover {
            background: #1d1d1b;
            color: #fff;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined100: css``,
      };
    case 'jetour':
      return {
        contained: css`
          background: #68a598;
          color: #fff;

          &:hover {
            background: #628788;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined: css`
          border: 1px solid #ccc;
          color: #4c4c4c;

          &:hover {
            opacity: 0.7;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        `,
        outlined100: css``,
      };
    default:
      return getDefaultVariantCssMap();
  }
};

const defaultButtonStyles = css<{
  variant?: ButtonVariant;
  themeType?: ThemeValuesType;
}>`
  position: relative;
  padding: 14px 26px;
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;

  ${({ variant, themeType }) =>
    typeof variant === 'string'
      ? getVariantCssMap(themeType)[variant]
      : variant}
`;

export const StyledButton = styled.button<{
  variant?: ButtonVariant;
  themeType?: ThemeValuesType;
}>`
  ${defaultButtonStyles}
`;

export const StyledLink = styled.a<{
  variant?: ButtonVariant;
  themeType?: ThemeValuesType;
}>`
  ${defaultButtonStyles}
`;

export const StyledNextLink = styled(Link)<{
  variant?: ButtonVariant;
  themeType?: ThemeValuesType;
}>`
  ${defaultButtonStyles}
`;
