import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { DetailedCarData } from '@/services/stock/typings';
import { fetchDetailedCarData } from '@/services/stock/stock-service';

interface StateType {
  stockCars: {
    status: FetchStatus;
    cars: DetailedCarData[];
  };
}

const initialState: StateType = {
  stockCars: {
    status: 'IDLE',
    cars: [],
  },
};

const slice = createSlice({
  name: 'special-offer',
  initialState,
  reducers: {
    setStockCarsLoading(state) {
      state.stockCars.status = 'LOADING';
    },
    setStockCarsSuccess(state, action: PayloadAction<DetailedCarData[]>) {
      state.stockCars.cars = action.payload;
      state.stockCars.status = 'SUCCESS';
    },
    setStockCarsFailure(state) {
      state.stockCars.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const { setStockCarsLoading, setStockCarsSuccess, setStockCarsFailure } =
  slice.actions;

export const getStockCarThunk =
  (carId: number): AppThunk<Promise<DetailedCarData>> =>
  async (dispatch, getState) => {
    dispatch(setStockCarsLoading());
    try {
      const state = getState();
      const carData = await fetchDetailedCarData('new', carId);

      if (state.specialOffer.stockCars.cars) {
        dispatch(
          setStockCarsSuccess([...state.specialOffer.stockCars.cars, carData])
        );
      } else {
        dispatch(setStockCarsSuccess([carData]));
      }

      return carData;
    } catch (error) {
      dispatch(setStockCarsFailure());
      return Promise.reject(error);
    }
  };

export function selectStockCars(state: AppState): DetailedCarData[] {
  return state.specialOffer.stockCars.cars;
}
