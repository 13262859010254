import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { cookie, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  IAvailability,
  ICarsBookmarks,
  ProfileCarModel,
  ProfileModel,
} from '@/typings/model';
import { checkCode, userApiService } from '@/services/users/users-service';

interface State {
  status: 'AUTHORIZED' | 'UNAUTHORIZED';
  token: Nullable<string>;
  profile: {
    status: 'IDLE' | 'PENDING' | 'SUCCEEDED' | 'FAILED';
    data: Nullable<ProfileModel>;
  };
}

const initialState: State = {
  status: 'UNAUTHORIZED',
  token: null,
  profile: {
    status: 'IDLE',
    data: null,
  },
};

export const accessTokenCookieName =
  process.env.NEXT_PUBLIC_COOKIE_ACCESS_TOKEN_PARAM || 'accessToken';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorized: (state) => {
      state.status = 'AUTHORIZED';
    },
    setUnauthorized: (state) => {
      state.status = 'UNAUTHORIZED';
    },
    setInitialUserProfile: (state) => {
      state.status = 'UNAUTHORIZED';
      state.token = null;
      state.profile = initialState.profile;
    },
    setProfilePending: (state) => {
      state.profile.status = 'PENDING';
    },
    setProfileSucceeded: (state, action: PayloadAction<ProfileModel>) => {
      state.profile.status = 'SUCCEEDED';
      state.profile.data = action.payload;
    },
    setProfileFailed: (state) => {
      state.profile.status = 'FAILED';
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setAuthorized,
  setUnauthorized,
  setInitialUserProfile,
  setProfilePending,
  setProfileSucceeded,
  setProfileFailed,
  setToken,
} = slice.actions;

/** Thunks **/

interface JwtPayloadNew {
  id: number;
  name: string;
  percent: number;
  cars: Array<ProfileCarModel>;
  availability: IAvailability;
  bookmarks: ICarsBookmarks[];
}

export function getProfileThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(setProfilePending());

    try {
      const token = userApiService.getAccessToken();

      if (!token) {
        dispatch(setProfileFailed());
        return;
      }

      const decoded = jwtDecode<JwtPayloadNew>(token);

      let payload: ProfileModel;

      payload = {
        firstName: decoded.name,
        percent: decoded.percent,
        cars: decoded.cars,
        hasCars: Boolean(decoded.cars?.length > 0),
        availability: decoded.availability,
        bookmarks: decoded.bookmarks,
      };

      if (payload) {
        dispatch(setProfileSucceeded(payload));
        dispatch(setToken(token));
      } else {
        dispatch(setProfileFailed());
        return;
      }
    } catch (error) {
      dispatch(setProfileFailed());
      return Promise.reject(error);
    }
  };
}

export function updateProfileThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      await dispatch(getProfileThunk());
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function checkAuthorizationThunk(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const isUserAuthorized = getIsUserProfileStatus(getState());

    if (isUserAuthorized) {
      return;
    }

    try {
      const token = userApiService.getAccessToken();

      if (!token) {
        dispatch(setUnauthorized());
        return;
      }

      await dispatch(getProfileThunk());

      dispatch(setAuthorized());
    } catch (error) {
      dispatch(setUnauthorized());
      return Promise.reject(error);
    }
  };
}

export const signInThunk =
  (...args: Parameters<typeof checkCode>): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const isUserAuthorized = getIsUserProfileStatus(getState());

    if (isUserAuthorized) {
      return;
    }

    try {
      const token = await checkCode(...args);
      cookie.remove(accessTokenCookieName);
      cookie.set(accessTokenCookieName, token.token);
      userApiService.setAccessToken(token.token, false);

      if (!token) {
        dispatch(setUnauthorized());
        return;
      }

      await dispatch(getProfileThunk()).then(() => {
        dispatch(setAuthorized());
      });
    } catch (error) {
      dispatch(setUnauthorized());
      return Promise.reject(error);
    }
  };

export const signInWithSocialThunk =
  (token: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const isUserAuthorized = getIsUserProfileStatus(getState());

    if (isUserAuthorized) {
      return;
    }

    try {
      userApiService.setAccessToken(token, false);

      if (!token) {
        dispatch(setUnauthorized());
        return;
      }

      await dispatch(getProfileThunk()).then(() => {
        dispatch(setAuthorized());
      });
    } catch (error) {
      dispatch(setUnauthorized());
      return Promise.reject(error);
    }
  };

export const signOutThunk = (): AppThunk => (dispatch, getState) => {
  const isUserAuthorized = getIsUserProfileStatus(getState());

  if (!isUserAuthorized) {
    return;
  }

  dispatch(setInitialUserProfile());
  cookie.remove(accessTokenCookieName);
  userApiService.setAccessToken(null);

  return Promise.resolve();
};

/** Selectors **/

export function getIsUserAuthorized(state: AppState): boolean {
  return state.auth.status === 'AUTHORIZED';
}

export function getIsUserProfileStatus(state: AppState): boolean {
  return state.auth.profile.status === 'SUCCEEDED';
}

export function getProfile(state: AppState): Nullable<ProfileModel> {
  return state.auth.profile.data;
}
