import { cookie, Nullable } from '@tager/web-core';

import { getTheme } from '@/constants/theme';
import {
  availablePagesDarkMode,
  availableTemplatesDarkMode,
} from '@/theme/constants';
import { NextRouterType } from '@/typings/common';

import { ThemeColorsType, ThemeTypes } from './types';
import {
  b2bLightColors,
  hisunLightColors,
  jacLightColors,
  jetourLightColors,
  mainDarkColors,
  mainLightColors,
} from './colors';

export const getInitialTheme = (router?: NextRouterType): ThemeTypes => {
  const theme = getTheme();
  const cookieTheme = cookie.get('theme') as Nullable<ThemeTypes>;

  switch (theme) {
    case 'main':
      if (cookieTheme && isThemeVisible(router)) {
        return cookieTheme;
      }

      return 'mainLight';
    case 'b2b':
      return 'b2bLight';
    case 'hisun':
      return 'hisunLight';
    case 'jac':
      return 'jacLight';
    case 'jetour':
      return 'jetourLight';
    default:
      return 'mainLight';
  }
};

export const getNextTheme = (currentTheme: ThemeTypes): ThemeTypes => {
  const theme = getTheme();

  switch (theme) {
    case 'main':
      return currentTheme === 'mainLight' ? 'mainDark' : 'mainLight';
    case 'b2b':
      return 'b2bLight';
    case 'hisun':
      return 'hisunLight';
    case 'jac':
      return 'jacLight';
    case 'jetour':
      return 'jetourLight';
    default:
      return 'mainLight';
  }
};

export const isThemeVisible = (router?: NextRouterType): boolean => {
  return (
    (process.env.NEXT_PUBLIC_SITE_DARK_THEME === '1' ||
      process.env.NEXT_PUBLIC_SITE_DARK_THEME === 'true') &&
    isThemeVisibleOnPage(router)
  );
};

export const getColorsByTheme = (theme: ThemeTypes): ThemeColorsType => {
  switch (theme) {
    case 'mainLight':
      return mainLightColors;
    case 'mainDark':
      return mainDarkColors;
    case 'b2bLight':
      return b2bLightColors;
    case 'hisunLight':
      return hisunLightColors;
    case 'jacLight':
      return jacLightColors;
    case 'jetourLight':
      return jetourLightColors;
    default:
      return mainLightColors;
  }
};

export const isThemeVisibleOnPage = (router?: NextRouterType): boolean => {
  if (router) {
    const pageType =
      router.components?.[router.route].props?.pageProps?.pageType;
    const pageTemplate =
      router.components?.[router.route].props?.pageProps?.template;

    return (
      availablePagesDarkMode.some((page) => page.includes(pageType)) ||
      availableTemplatesDarkMode.some((template) =>
        template.includes(pageTemplate)
      )
    );
  }

  return true;
};
