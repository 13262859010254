import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getModelPage } from '@/services/requests';
import { ModelPage } from '@/typings/model';
import {
  ModelConfigurationsPageData,
  ModelPageData,
} from '@/services/catalog/typings';
import {
  getModelPageConfigurationsData,
  getModelPageData,
} from '@/services/catalog/catalog-service';

interface State {
  carModelSeoStatus: FetchStatus;
  carModelSeo: Nullable<ModelPage>;

  carModelStatus: FetchStatus;
  carModel: Nullable<ModelPageData>;

  carModelConfigurationsStatus: FetchStatus;
  carModelConfigurations: Nullable<ModelConfigurationsPageData[]>;
  isDifferentParams: boolean;
}

const initialState: State = {
  carModelSeoStatus: 'IDLE',
  carModelSeo: null,

  carModelStatus: 'IDLE',
  carModel: null,

  carModelConfigurationsStatus: 'IDLE',
  carModelConfigurations: null,
  isDifferentParams: false,
};

const slice = createSlice({
  name: 'single-model-car',
  initialState,
  reducers: {
    fetchCarModelSeoLoading: (state) => {
      state.carModelSeoStatus = 'LOADING';
    },
    fetchCarModelSeoSuccess: (state, action: PayloadAction<ModelPage>) => {
      state.carModelSeoStatus = 'SUCCESS';
      state.carModelSeo = action.payload;
    },
    fetchCarModelSeoFailure: (state) => {
      state.carModelSeoStatus = 'FAILURE';
    },

    fetchCarModelLoading: (state) => {
      state.carModelStatus = 'LOADING';
    },
    fetchCarModelSuccess: (state, action: PayloadAction<ModelPageData>) => {
      state.carModelStatus = 'SUCCESS';
      state.carModel = action.payload;
    },
    fetchCarModelFailure: (state) => {
      state.carModelStatus = 'FAILURE';
    },

    fetchCarModelConfigurationsLoading: (state) => {
      state.carModelConfigurationsStatus = 'LOADING';
    },
    fetchCarModelConfigurationsSuccess: (
      state,
      action: PayloadAction<ModelConfigurationsPageData[]>
    ) => {
      state.carModelConfigurationsStatus = 'SUCCESS';
      state.carModelConfigurations = action.payload;
    },
    fetchCarModelConfigurationsFailure: (state) => {
      state.carModelConfigurationsStatus = 'FAILURE';
    },
    setDifferentParams: (state, action: PayloadAction<boolean>) => {
      state.isDifferentParams = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  fetchCarModelSeoLoading,
  fetchCarModelSeoSuccess,
  fetchCarModelSeoFailure,

  fetchCarModelLoading,
  fetchCarModelSuccess,
  fetchCarModelFailure,

  fetchCarModelConfigurationsLoading,
  fetchCarModelConfigurationsSuccess,
  fetchCarModelConfigurationsFailure,
  setDifferentParams,
} = slice.actions;

/** Thunks **/

export const fetchCarModelSeoThunk =
  (brand: string, model: string): AppThunk<Promise<Nullable<ModelPage>>> =>
  async (dispatch) => {
    dispatch(fetchCarModelSeoLoading());
    try {
      const carModelSeo = await getModelPage(`${brand}/${model}`);
      dispatch(fetchCarModelSeoSuccess(carModelSeo));
      return carModelSeo;
    } catch (error) {
      dispatch(fetchCarModelSeoFailure());
      return Promise.reject(error);
    }
  };

export const fetchCarModelThunk =
  (generationId: number): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(fetchCarModelLoading());
    try {
      const carModel = await getModelPageData(generationId);
      dispatch(fetchCarModelSuccess(carModel));
    } catch (error) {
      dispatch(fetchCarModelFailure());
      return Promise.reject(error);
    }
  };

export const fetchCarModelConfigurationsThunk =
  (generationId: number): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(fetchCarModelConfigurationsLoading());
    try {
      const carModelConfigurations = await getModelPageConfigurationsData(
        generationId
      );
      dispatch(fetchCarModelConfigurationsSuccess(carModelConfigurations));
    } catch (error) {
      dispatch(fetchCarModelConfigurationsFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectCarModelSeo = (state: AppState): Nullable<ModelPage> =>
  state.singleModelCar.carModelSeo;

export const selectCarModel = (state: AppState): Nullable<ModelPageData> =>
  state.singleModelCar.carModel;

export const selectCarModelConfigurations = (
  state: AppState
): ModelConfigurationsPageData[] =>
  state.singleModelCar.carModelConfigurations ?? [];

export const getDifferentParams = (state: AppState): boolean => {
  return state.singleModelCar.isDifferentParams;
};
