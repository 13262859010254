import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

function Button({ children, ...other }: Props) {
  return <Component {...other}>{children}</Component>;
}

export default Button;

const Component = styled.button`
  display: inline-block;
  font-weight: 500;
  line-height: 26px;
  color: #212529;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 30px;
  font-size: 22px;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  ${media.laptop(css`
    padding: 10px 20px;
    font-size: 18px;
  `)}

  &:hover {
    color: #212529;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  ${media.tabletSmall(css`
    padding: 10px 20px;
  `)}
`;
