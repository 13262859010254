import { Nullable } from '@tager/web-core';

import { PreviewSvgIconItems } from '@/components/DetailedCarPreviewSpecification/DetailedCarPreviewSpecification.types';
import {
  DetailedCarData,
  SimilarCarsDataParams,
} from '@/services/stock/typings';
import { SEOTemplate } from '@/typings/model';
import { getTheme } from '@/constants/theme';
import {
  CarsStockBattery,
  CarsStockBody,
  CarsStockFuel,
  CarsStockGearbox,
  CarsStockWheel,
} from '@/services/stock/enums';
import { PartnersCarsTestDriveParams } from '@/services/leads/typings';
import { submitPartnersCarsTestDrive } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

export function svgIconItem(
  item: CarsStockBody | CarsStockFuel | CarsStockGearbox | CarsStockWheel
) {
  switch (item) {
    case CarsStockBody.Coupe:
      return PreviewSvgIconItems.COUPE;
    case CarsStockBody.Van:
      return PreviewSvgIconItems.VAN;
    case CarsStockBody.Hatchback:
      return PreviewSvgIconItems.HATCHBACK;
    case CarsStockBody.Cabriolet:
      return PreviewSvgIconItems.CABRIOLET;
    case CarsStockBody.Crossover:
      return PreviewSvgIconItems.CROSSOVER;
    case CarsStockBody.LiftBack:
      return PreviewSvgIconItems.LIFT_BACK;
    case CarsStockBody.Minibus:
      return PreviewSvgIconItems.MINIBUS;
    case CarsStockBody.Bus:
      return PreviewSvgIconItems.BUS;
    case CarsStockBody.Minivan:
      return PreviewSvgIconItems.MINIVAN;
    case CarsStockBody.Sedan:
      return PreviewSvgIconItems.SEDAN;
    case CarsStockBody.Chassis:
      return PreviewSvgIconItems.CHASSIS;
    case CarsStockBody.StationWagon:
      return PreviewSvgIconItems.STATION_WAGON;
    case CarsStockBody.Suv:
      return PreviewSvgIconItems.SUV;
    case CarsStockBody.Pickup:
      return PreviewSvgIconItems.PICKUP;
    case CarsStockFuel.Electric:
      return PreviewSvgIconItems.ELECTRIC;
    case CarsStockGearbox.Manual:
      return PreviewSvgIconItems.MANUAL;
    case CarsStockGearbox.Robot:
      return PreviewSvgIconItems.ROBOT;
    default:
      return PreviewSvgIconItems.DEFAULT;
  }
}

export function switchNamesItems(
  item:
    | CarsStockFuel
    | CarsStockGearbox
    | CarsStockWheel
    | CarsStockBody
    | CarsStockBattery
) {
  switch (item) {
    case CarsStockFuel.Petrol:
      return 'Бензин';
    case CarsStockFuel.Diesel:
      return 'Дизель';
    case CarsStockFuel.Gas:
      return 'Газ';
    case CarsStockFuel.Electric:
      return 'Электро ☘️';
    case CarsStockFuel.Hybrid:
      return 'Гибрид';
    case CarsStockGearbox.Manual:
      return 'Механическая';
    case CarsStockGearbox.Automatic:
      return 'Автоматическая';
    case CarsStockGearbox.Robot:
      return 'Робот';
    case CarsStockGearbox.Variator:
      return 'Вариатор';
    case CarsStockGearbox.DSG:
      return 'DSG';
    case CarsStockGearbox.Reductor:
      return 'Редуктор';
    case CarsStockWheel.Front:
      return 'Передний';
    case CarsStockWheel.Back:
      return 'Задний';
    case CarsStockWheel.Full:
      return 'Полный';
    case CarsStockBody.Sedan:
      return 'Седан';
    case CarsStockBody.LiftBack:
      return 'Лифтбэк';
    case CarsStockBody.Crossover:
      return 'Кроссовер';
    case CarsStockBody.Minibus:
      return 'Микроавтобус';
    case CarsStockBody.Van:
      return 'Фургон';
    case CarsStockBody.Suv:
      return 'Внедорожник';
    case CarsStockBody.Hatchback:
      return 'Хэтчбэк';
    case CarsStockBody.Coupe:
      return 'Купе';
    case CarsStockBody.Pickup:
      return 'Пикап';
    case CarsStockBody.StationWagon:
      return 'Универсал';
    case CarsStockBody.Minivan:
      return 'Минивэн';
    case CarsStockBody.Bus:
      return 'Автобус';
    case CarsStockBody.Chassis:
      return 'Шасси';
    case CarsStockBody.Cabriolet:
      return 'Кабриолет';
    case CarsStockBody.Atv:
      return 'Квадроцикл';
    case CarsStockBody.Buggy:
      return 'Багги';
    case CarsStockBattery.LithiumIon:
      return 'Литий-ионная';
    case CarsStockBattery.LithiumIron:
      return 'Литий-железо-фосфатная';
    case CarsStockBattery.LithiumNickel:
      return 'Литий-никель-марганец-кобальт-оксидная';
    case CarsStockBattery.LithiumPolymeric:
      return 'Литий-полимерная';
    case CarsStockBattery.MetalAir:
      return 'Метало-воздушная';
    default:
      return '';
  }
}

export const convertDetailedCarSEOTemplate = (
  data: Nullable<DetailedCarData>,
  seoTemplate: Nullable<SEOTemplate>,
  carId: number
): Nullable<SEOTemplate> => {
  if (!data || !seoTemplate) {
    return null;
  }

  const { title, description, h1, keywords, openGraphImage } = seoTemplate;

  const replaceText = (text: Nullable<string>): Nullable<string> => {
    const brand = data.brand.name;
    const model = data.model.name;
    const body = data.params.bodyType
      ? switchNamesItems(data.params.bodyType)
      : '';
    const year = data.year ? String(data.year) : '';
    const volume = data.params.engineCapacity
      ? String(data.params.engineCapacity)
      : '';
    const color = data.color && data.color.label ? data.color.label : '';
    const vin = data.vin ? data.vin : String(carId);
    const fuel = data.params.fuel ? switchNamesItems(data.params.fuel) : '';
    const gearbox = data.params.gearbox
      ? switchNamesItems(data.params.gearbox)
      : '';

    return (
      text
        ?.replaceAll('{{brand}}', brand)
        ?.replaceAll('{{brand|lowercase}}', brand.toLowerCase())
        ?.replaceAll('{{model}}', model)
        ?.replaceAll('{{model|lowercase}}', model.toLowerCase())
        ?.replaceAll('{{body}}', body)
        ?.replaceAll('{{body|lowercase}}', body.toLowerCase())
        ?.replaceAll('{{year}}', year)
        ?.replaceAll('{{volume}}', volume)
        ?.replaceAll('{{color}}', color)
        ?.replaceAll('{{color|lowercase}}', color.toLowerCase())
        ?.replaceAll('{{vin}}', vin)
        ?.replaceAll('{{fuel}}', fuel)
        ?.replaceAll('{{fuel|lowercase}}', fuel.toLowerCase())
        ?.replaceAll('{{gearbox}}', gearbox)
        ?.replaceAll('{{gearbox|lowercase}}', gearbox.toLowerCase())
        ?.split(' ')
        ?.filter((item) => item)
        ?.join(' ') ?? text
    );
  };

  return {
    title: replaceText(title),
    description: replaceText(description),
    h1,
    keywords,
    openGraphImage: openGraphImage ?? data.openGraphImage,
  };
};

export const getSimilarCarsDataParams = (
  detailedCarData: DetailedCarData
): SimilarCarsDataParams | undefined => {
  const theme = getTheme();

  return theme !== 'main'
    ? { brands: String(detailedCarData.brand.id) }
    : undefined;
};

export async function handleSubmitPartnersCarsTestDriveForm(
  values: PartnersCarsTestDriveParams,
  event?: string
): Promise<void> {
  try {
    await submitPartnersCarsTestDrive(values).then(() => {
      if (event) {
        googleEvent(event);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}
