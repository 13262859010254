import { request } from '@tager/web-core';
import { SentryIssueResponse } from '@tager/web-components/src/modules/Error/components/ErrorDevelop/ErrorDevelop.types';

import {
  IAmpLocationsList,
  Dealer,
  DealerReviews,
  BlogPageSeo,
  BlogTag,
  BlogPosts,
  TestDriveData,
  BlogSinglePost,
  LatestNews,
  TestDriveLocation,
  MainMenuBannersData,
  ModelPage,
  HomeBannersData,
  CarsStockBannerResponseType,
  DetailedCarBannerResponseType,
  ServiceBlogPost,
  SEOTemplatesTypes,
  SEOTemplate,
  RateCarOnlineParams,
  BlogCategory,
  CarsStockBannerParams,
  DetailedCarBannerParams,
} from '@/typings/model';

export async function getErrorDetails(id: string) {
  return (await getIssueById(id))?.data;
}

function getIssueById(id: string): Promise<{ data: SentryIssueResponse }> {
  return request.get({ path: `/tager/sentry-issue/${id}` });
}

/** Amp Landing */

export function getAmpLocations(): Promise<IAmpLocationsList> {
  return request.get({ path: `/dealers/amp` });
}

/** Dealers **/

export function loadDealers(): Promise<{ data: Dealer[] }> {
  return request.get({ path: `/dealers` });
}

/** Dealer **/

export function loadDealerByAlias(alias: string): Promise<Dealer> {
  return request.get({ path: `/dealers/view?urlAlias=${alias}` });
}

export function loadDealerReviews(
  dealerId: number,
  offset: number,
  sort: string,
  limit: number
): Promise<DealerReviews> {
  return request.get({
    path: `/dealers/${dealerId}/reviews`,
    params: {
      sort,
      offset,
      limit,
    },
  });
}

/** Blog **/

export function loadBlogSeoByAlias(
  urlAlias: BlogCategory
): Promise<BlogPageSeo> {
  return request.get({
    path: '/blog/categories/view',
    params: {
      urlAlias,
    },
  });
}

export function loadBlogTagsByCategory(
  category: BlogCategory
): Promise<BlogTag[]> {
  return request.get({
    path: `/blog/tags`,
    params: {
      category,
    },
  });
}

export function loadBlogPostsByCategory(
  category: BlogCategory,
  tags?: string[],
  limit?: number
): Promise<BlogPosts> {
  return request.get({
    path: '/blog/posts',
    params: {
      category,
      offset: 0,
      limit: limit ? limit : 9999,
      tags: tags?.join(','),
    },
  });
}

export function loadServicePosts(
  tags: string[] = []
): Promise<ServiceBlogPost[]> {
  return request.get({
    path: '/blog/service-posts',
    params: {
      tags: tags.length > 0 ? tags?.join(',') : null,
    },
  });
}

export function loadBlogPost(
  category: BlogCategory,
  urlAlias: string
): Promise<BlogSinglePost> {
  return request.get({
    path: `/blog/posts/view`,
    params: {
      category,
      urlAlias,
    },
  });
}

/** Test-drive **/

export function getTestDriveCarData(
  carId: number,
  carType: string
): Promise<TestDriveData> {
  return request.get({ path: `/cars/${carType}/${carId}/test-drive` });
}

export function getFreeTimeForDealer(
  carId: number,
  carType: string,
  date: string,
  location: number
): Promise<Array<string>> {
  return request.get({
    path: `/cars/${carType}/${carId}/test-drive/timeslots`,
    params: {
      date,
      location,
    },
  });
}

/** Quiz **/

export function submitQuiz(
  id: string | number,
  payload: number | string
): Promise<{ data: { success: boolean; values: number[] } }> {
  return request.post({
    path: `/blog/posts/${id}/vote`,
    body: { answer: payload },
  });
}

/** Amp cars count **/

export function getAmpCarsCount(): Promise<{ count: number }> {
  return request.get({ path: `/cars/amp/count` });
}

/** Last news **/

export function getLastNews(): Promise<LatestNews[]> {
  return request.get({ path: `/blog/news/last` });
}

/** Purchase **/

export function loadCarLocations(id: string): Promise<TestDriveLocation[]> {
  return request.get({ path: `/cars/new/${id}/locations` });
}

/** Banners **/

export function loadMainMenuBannersData(): Promise<MainMenuBannersData> {
  return request.get({ path: `/tager/adv/main_menu?limit=1&random=1` });
}

export function loadHomeBigBannersData(): Promise<HomeBannersData> {
  return request.get({ path: `/tager/adv/home_big` });
}

export function loadHomeSmallBannersData(): Promise<HomeBannersData> {
  return request.get({ path: `/tager/adv/home_small` });
}

export function loadCarDetailedBannerData(
  params: DetailedCarBannerParams
): Promise<DetailedCarBannerResponseType> {
  return request.get({
    path: `/banners/cars-detailed`,
    params,
  });
}

export function loadCarsStockBannerData(
  params: CarsStockBannerParams
): Promise<CarsStockBannerResponseType> {
  return request.get({
    path: `/banners/cars`,
    params: params,
  });
}

/** Single Model **/

export function getModelPage(path: string): Promise<ModelPage> {
  return request.get({ path: `/pages/model-page`, params: { path } });
}

/** Geocoder **/

export function getGeocoderLocality(query: string): Promise<string[]> {
  return request.get({ path: '/geocoder/locality', params: { query } });
}

export function getGeocoderStreets(query: string): Promise<string[]> {
  return request.get({ path: `/geocoder/streets`, params: { query } });
}

/** SEO Templates **/

export function getSEOTemplate(
  type: SEOTemplatesTypes
): Promise<{ data: SEOTemplate }> {
  return request.get({ path: `/tager/seo/template/${type}` });
}

/** Zoomos **/

export function getZoomosBrands(): Promise<string[]> {
  return request.get({ path: `/zoomos/brands` });
}

export function getZoomosModels(brand: string): Promise<string[]> {
  return request.get({
    path: `/zoomos/models`,
    params: {
      brand,
    },
  });
}

export function getZoomosYears(
  brand: string,
  model: string
): Promise<{ min: number; max: number | null }> {
  return request.get({
    path: `/zoomos/years`,
    params: {
      brand,
      model,
    },
  });
}

export function getZoomosBodyTypes(
  brand: string,
  model: string,
  year: string
): Promise<string[]> {
  return request.get({
    path: `/zoomos/bodies`,
    params: {
      brand,
      model,
      year,
    },
  });
}

export function getZoomosGenerations(
  brand: string,
  model: string,
  year: string,
  bodyTypeReq: string
): Promise<string[]> {
  return request.get({
    path: `/zoomos/generations`,
    params: {
      brand,
      model,
      year,
      body: bodyTypeReq,
    },
  });
}

export function getZoomosChosenParameters(
  brand: string,
  model: string,
  year: string,
  bodyTypeReq: string,
  generationsReq: string
): Promise<{
  fuels: string[];
  gearboxes: string[];
  engineVolumes: number[];
  enginePowers: number[];
}> {
  return request.get({
    path: `/zoomos/params`,
    params: {
      brand,
      model,
      year,
      body: bodyTypeReq,
      generation: generationsReq,
    },
  });
}

/** Rate car **/
export function submitRateCarOnline(params: RateCarOnlineParams) {
  return request.post({
    path: `/leads/rate-car/online`,
    body: params,
  });
}
