import { IncomingMessage, ServerResponse } from 'http';

import React from 'react';
import moment from 'moment';
import Router from 'next/router';

import {
  cookie,
  isBrowser,
  isNotNullish,
  Nullable,
  RequestError,
} from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { OptionType } from '@/typings/common';
import { Dealer, StringField } from '@/typings/model';
import { setMobileMenuOpened } from '@/store/reducers/layout';
import { StoreDispatch } from '@/store/store';
import { CallbackFormFields } from '@/components/modals/CallbackForm/CallbackForm.types';
import {
  submitFormCallback,
  submitPartnersCarsRequest,
} from '@/services/leads/leads-service';
import { getTheme } from '@/constants/theme';
import {
  CatalogCar,
  CatalogStockBrandAndModelsResponse,
} from '@/services/catalog/typings';
import {
  PartnersCarsRequestParams,
  RequestSourceType,
} from '@/services/leads/typings';
import { googleEvent } from '@/utils/events';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertAliasToPath(
  alias: Array<string> | string | undefined
): string {
  if (!alias) return '';
  const convertString = convertSlugToPath(alias);

  return convertString.substring(convertString.indexOf('/') + 1);
}

export const getCurrentPath = (
  pathName: string,
  slug: string[] | string | undefined
): string => {
  return `${
    pathName === '/[[...slug]]' ? '' : `/${pathName.split('/')[1]}`
  }${convertSlugToPath(slug)}`;
};

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if (error && 'status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

type ParamValue = string | (string | null)[];

type QueryType = { [key in string]?: ParamValue };

export function getFilterParamAsStringArray(
  query: QueryType,
  key: string
): Array<string> {
  const queryValue = query[key];

  if (Array.isArray(queryValue)) {
    return queryValue.filter(isNotNullish);
  }

  if (queryValue) {
    return [queryValue];
  }

  return [];
}

export function handleMobileMenuClose(
  isMobile: boolean,
  dispatch: StoreDispatch
) {
  if (isMobile) {
    dispatch(setMobileMenuOpened(false));
  }
}

export function transformDate(birthdate: StringField) {
  if (!birthdate) {
    return null;
  }

  return birthdate.split('-').reverse().join('.');
}

export function formatDate(date: Date | null) {
  if (!date) {
    return '';
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  day = day < 10 ? Number('0' + day) : day;
  month = month < 10 ? Number('0' + month) : month;

  return `${year}-${month}-${day}`;
}

export async function handleSubmitCallbackForm(
  values: CallbackFormFields
): Promise<void> {
  try {
    await submitFormCallback(values);
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export function getCityFromCookie(req?: IncomingMessage): Nullable<OptionType> {
  const cityFromCookie = cookie.get('selectedCity', req);

  return cityFromCookie ? JSON.parse(cityFromCookie) : null;
}

export const getRequestSource = (): RequestSourceType => {
  const theme = getTheme();

  switch (theme) {
    case 'b2b':
      return 'B2B';
    case 'hisun':
      return 'HISUN';
    case 'jac':
      return 'JAC';
    case 'jetour':
      return 'JETOUR';
    default:
      return 'ATLANTM';
  }
};

export const getDealerReplacementPhones = (
  dealerId?: {
    id?: number;
    apiId?: number;
  },
  dealers?: Dealer[]
): string[] => {
  if ((dealerId?.id || dealerId?.apiId) && dealers && dealers.length !== 0) {
    const foundDealer = dealers.find((dealer) =>
      dealerId?.id
        ? dealer.id === dealerId.id
        : dealerId?.apiId
        ? dealer.dealerId === dealerId.apiId
        : undefined
    );

    if (foundDealer) {
      return foundDealer.locations?.[0]?.phones ?? [];
    } else {
      return [];
    }
  }

  return [];
};

export const getCatalogCarsByIds = (
  cars: CatalogCar[],
  ids: string[]
): CatalogCar[] => {
  return cars.filter((car) => ids.includes(String(car.id)));
};

export async function handleSubmitPartnersCarsRequestForm(
  values: PartnersCarsRequestParams,
  event?: string
): Promise<void> {
  try {
    await submitPartnersCarsRequest(values).then(() => {
      if (event) {
        googleEvent(event);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export const getCatalogStockBrandDealerIds = (
  brands: CatalogStockBrandAndModelsResponse[],
  searchId: number
): number[] => {
  return brands.find((brand) => brand.id === searchId)?.dealerIds ?? [];
};

export const getSubdomain = (): string =>
  isBrowser()
    ? window.location.hostname.substring(
        0,
        window.location.hostname.indexOf('.')
      )
    : '';

export const getFirstDateOfYear = (): string =>
  moment().startOf('year').format('YYYY-MM-DD');

export const getLastDateOfYear = (): string =>
  moment().endOf('year').format('YYYY-MM-DD');

export const redirect = (
  location: string,
  options?: {
    hardReload?: boolean;
  },
  res?: ServerResponse,
  code: number = 302
): void => {
  if (res) {
    res.writeHead(code, { Location: location }).end();
  } else {
    if (options?.hardReload) {
      window.location.href = location;
    } else {
      Router.replace(location);
    }
  }
};

export const getPhoneLink = (phone: string) => {
  return `tel:+${phone.replace(/[^0-9]+/g, '')}`;
};

export const getCarsStockPriceText = (
  phone?: string,
  isPartnerCar?: boolean
): string => {
  return `<p style="margin-left:0px;">
            Сформируйте заказ по номеру:&nbsp;
          </p>
          <p style="margin-left:0px;">
            <a href="${getPhoneLink(
              (!isPartnerCar ? phone : '+375 44 502-95-43') ?? ''
            )}">${!isPartnerCar ? phone : '+375 44 502-95-43'}</a>
          </p>`;
};

export const getAverageRatingAndCountReviewsDealers = (
  dealers: Dealer[]
): { rating: number; ratingCount: number } => {
  let rating = 0;
  let ratingCount = 0;

  dealers.forEach((dealer) => {
    rating += dealer.rating;
    ratingCount += dealer.reviewsCount;
  });

  return { rating: Number((rating / dealers.length).toFixed(2)), ratingCount };
};
