import { RequestSourceType } from '@/services/leads/typings';
import { CarsType } from '@/typings/common';
import { ApplicationModalType } from '@/components/modals/DetailedCarModal/DetailedCarModal';

export const INITIAL_FORM_VALUES = {
  type: 'new' as CarsType,
  id: 0,
  creditAmount: '',
  brand: '',
  model: '',
  year: '',
  name: '',
  phone: '',
  email: '',
  comment: '',
  source: 'ATLANTM' as RequestSourceType,
};

export function getInitialOptions(
  typeCar: CarsType,
  typeApplicationModal: ApplicationModalType
) {
  const baseFormOptions = [
    { value: 1, label: 'Рассчитать кредит', checked: false },
    { value: 2, label: 'Оценить свой автомобиль', checked: false },
  ];
  return typeCar === 'new'
    ? baseFormOptions
    : typeApplicationModal === 'CUSTOM_PRICE'
    ? [...baseFormOptions, { value: 3, label: 'Своя цена', checked: true }]
    : baseFormOptions;
}
