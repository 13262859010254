import styled from 'styled-components';

import { ModalOverlay } from '@tager/web-components';

export const StyledOverlay = styled(ModalOverlay)`
  background: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  padding: 20px 10px;

  & > div {
    margin: auto;
    transform: none !important;
    transition: opacity 0.15s linear !important;
  }
` as typeof ModalOverlay;
