import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { DetailedCarData } from '@/services/stock/typings';
import { fetchDetailedCarData } from '@/services/stock/stock-service';
import { CarsType } from '@/typings/common';

interface CompareData extends DetailedCarData {
  id?: number;
}

interface State {
  status: FetchStatus;
  data: Nullable<CompareData[]>;
  isDifferentParams: boolean;
}

const initialState: State = {
  status: 'IDLE',
  data: null,
  isDifferentParams: false,
};

const slice = createSlice({
  name: 'cars-stock-compare',
  initialState,
  reducers: {
    setCompareCarLoading: (state) => {
      state.status = 'LOADING';
    },
    setCompareCarSuccess: (
      state,
      action: PayloadAction<Nullable<CompareData[]>>
    ) => {
      state.data = action.payload;
      state.status = 'SUCCESS';
    },
    setCompareCarFailure: (state) => {
      state.status = 'FAILURE';
    },
    setDifferentParams: (state, action: PayloadAction<boolean>) => {
      state.isDifferentParams = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setCompareCarLoading,
  setCompareCarSuccess,
  setCompareCarFailure,
  setDifferentParams,
} = slice.actions;

/** Thunks **/

export const getCompareCarThunk =
  (carType: CarsType, carId: number): AppThunk<Promise<CompareData>> =>
  async (dispatch, getState) => {
    dispatch(setCompareCarLoading());
    try {
      const state = getState();
      const carData: CompareData = await fetchDetailedCarData(carType, carId);
      carData.id = carId;

      if (state.carsStockCompare.data) {
        dispatch(
          setCompareCarSuccess([...state.carsStockCompare.data, carData])
        );
      } else {
        dispatch(setCompareCarSuccess([carData]));
      }

      return carData;
    } catch (error) {
      dispatch(setCompareCarFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const getCarsStockCompareData = (
  state: AppState
): Nullable<CompareData[]> => {
  return state.carsStockCompare.data;
};

export const getDifferentParams = (state: AppState): boolean => {
  return state.carsStockCompare.isDifferentParams;
};
